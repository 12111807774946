<template>
  <div
    id="recent-tickets"
    class="d-flex">
    <select v-model="selectedTicket">
      <option
        v-for="ticket in tickets.value"
        :key="ticket.id"
        :value="ticket">
        {{ ticket.sequence }} - ${{ ticket.amount }}
      </option>
    </select>
    <button
      class="cancel-ticket"
      @click="onCancelTicket">
      <font-awesome-icon
        icon="trash"
        size="2x" />
    </button>
  </div>
</template>

<script>
import TicketPreview from "@/components/tickets/TicketPreview.vue";

export default {
  name: "RecentTickets",
  components: { TicketPreview },
  props: {
    tickets: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      selectedTicket: null,
      showTicketPreview: false,
    };
  },
  watch: {
    "tickets.value"(items) {
      if (items.length > 0) {
        this.selectedTicket = items[0];
      } else {
        this.selectedTicket = null;
      }
    },
  },
  methods: {
    onCancelTicket() {
      if (this.selectedTicket) {
        this.$emit("cancel-ticket", { id: this.selectedTicket.id });
      }
    },
  },
};
</script>

<style scoped>
#recent-tickets {
  height: 46px;
}

select {
  width: 100%;
  height: 100%;
  border: 1px solid white;
  background-color: white;
  font-size: 115%;
  font-weight: bold;
  padding: 5px;
  border-radius: 2px;
}

.cancel-ticket {
  background-color: #d53939;
  padding-left: 8px;
  padding-right: 8px;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}

</style>
