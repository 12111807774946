<template>
  <div>
<!--    <div>-->
<!--      {{ $t("views.tickets.monitor.legend") }}-->
<!--    </div>-->
    <div class="d-flex">
      <span class="winner-legend white--text">
        {{ $t("enumerations.ticketStatus.winner") }}
      </span>
      <span class="loser-legend white--text">
        {{ $t("enumerations.ticketStatus.loser") }}
      </span>
      <span class="pending-legend black--text">
        {{ $t("enumerations.ticketStatus.pending") }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "PlayLegend",
};
</script>

<style scoped lang="scss">
span {
  display: flex;
  width: 100%;
  justify-content: center;
  border: 1px solid gray;
}

.winner-legend {
  background-color: var(--v-success-base);
}

.loser-legend {
  background-color: var(--v-danger-base);
}
</style>
