<template>
  <v-switch
    :value="divisionBet.value"
    label="Division bet"
    color="green"
    hide-details="auto"
    dark
    @change="$emit('update:division-bet', $event !== null)" />
</template>

<script>
export default {
  name: "DivisionBetToggle",
  props: {
    divisionBet: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped>

</style>
