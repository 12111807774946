<template>
  <div>
    <v-data-table
      id="plays-table"
      :headers="headers"
      :items="plays.value"
      :items-per-page="3"
      class="elevation-1"
      centered
      disable-pagination
      disable-sort
      disable-filtering
      hide-default-footer
      fixed-header
      height="30vh">
      <template #header.amount="{header}">
        {{ header.text }}
        <v-tooltip
          v-if="divisionBet"
          right>
          <template #activator="{on, attrs}">
            <v-icon
              color="primary"
              v-bind="attrs"
              v-on="on">
              mdi-link
            </v-icon>
          </template>
          <span>Division bet</span>
        </v-tooltip>
      </template>
      <template #footer>
        <div id="table-footer">
          {{ $t("views.tickets.create.total", { amount: totalAmount }) }}
        </div>
      </template>
      <template #item.playType="{ item }">
        <!-- Single -->
        <div
          v-if="item.playType === PlayType.straight">
          <v-chip
            :color="item.color"
            dark>
            {{ item.value }}
          </v-chip>
        </div>
        <!-- Column -->
        <div
          v-if="item.playType === PlayType.column"
          class="selected-play-wrapper">
          <div class="modifier">
            (2 - 1)
            <v-chip
              :color="item.color"
              dark>
              {{ item.value }}
            </v-chip>
          </div>
        </div>
        <!-- Dozens -->
        <div
          v-if="item.playType === PlayType.dozen"
          class="selected-play-wrapper">
          <div class="modifier">
            {{ dozenPlayPreviewText(item.value) }}
          </div>
        </div>
        <!-- Low -->
        <div
          v-if="item.playType === PlayType.low"
          class="selected-play-wrapper">
          1 - 18
        </div>
        <!-- High -->
        <div
          v-if="item.playType === PlayType.high"
          class="selected-play-wrapper">
          19 to 36
        </div>
        <!-- Even -->
        <div
          v-if="item.playType === PlayType.even">
          Even
        </div>
        <!-- Odd -->
        <div
          v-if="item.playType === PlayType.odd">
          Odd
        </div>
        <!-- Red -->
        <div
          v-if="item.playType === PlayType.red">
          Red
        </div>
        <!-- Black -->
        <div
          v-if="item.playType === PlayType.black"
          class="selected-play-wrapper">
          Black
        </div>
      </template>
      <template #item.actions="{ item }">
        <v-icon
          @click="deleteItem(item)">
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import PlayType from "@/imported/PlayType.json";
import RouletteDisplayFormatters from "@/mixins/RouletteDisplayFormatters.js";

export default {
  name: "PlaysTable",
  mixins: [RouletteDisplayFormatters],
  props: {
    plays: {
      type: Object,
      required: true,
    },
    divisionBet: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      PlayType,
    };
  },
  computed: {
    totalAmount() {
      return this.plays.value.map(p => p.amount).
        reduce((a, b) => a + b, 0);
    },
    headers() {
      return [
        {
          text: this.$t("attributes.plays.play"),
          align: "center",
          sortable: false,
          value: "playType",
        },
        {
          text: this.$t("attributes.plays.amount"),
          align: "center",
          value: "amount",
        },
        {
          text: this.$t("attributes.plays.payout"),
          align: "center",
          value: "payout",
        },
        {
          text: this.$t("actions.text"),
          align: "center",
          value: "actions",
          sortable: false,
        },
      ];
    },
  },
  methods: {
    deleteItem(item) {
      const index = this.plays.value.indexOf(item);

      this.$emit("deletePlay", index);
    },
  },
};
</script>

<style
  lang="scss"
  scoped>
#plays-table {
  border-radius: 10px;

  ::v-deep .v-data-table__wrapper {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  ::v-deep th {
    border-radius: 0;
  }
}

::v-deep table {
  background: white;

  td {
    white-space: nowrap;
  }
}

::v-deep thead th, #table-footer {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  text-align: center;
  height: 100%;
  font-size: 2em;
  font-weight: bolder;
  background: darkseagreen !important;
}
</style>
