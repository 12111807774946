<template>
  <div
    id="ticket-monitor-wrapper"
    class="transparent-background">
    <validation-observer
      ref="validationObserver">
      <v-container
        fluid
        background-color="blue-grey">
        <v-row
          class="mt-3">
          <v-col
            cols="4">
            <menu-date-picker
              v-model="date"
              :date-picker-options="{
                allowedDates: allowedDates
              }"
              :input-options="{
                'prepend-inner-icon': 'mdi-calendar',
                'dark': true,
                'dense': true,
                'outlined': true,
                'backgroundColor': 'blue-grey darken-4',
                'label': $t('views.tickets.monitor.date')
              }" />
          </v-col>
          <v-col
            v-if="mode === 'admin'"
            md="3"
            cols="4">
            <validation-provider
              v-slot="{ errors }"
              :rules="validations.bettingPool"
              name="betting_pools"
              :disabled="form.pendingPayment"
              slim>
              <v-autocomplete
                v-model="form.bettingPoolId"
                :items="params.bettingPools"
                :error-messages="errors"
                item-text="label"
                item-value="value"
                :label="$t('attributes.tickets.betting_pool')+'s'"
                color="blue-grey"
                background-color="blue-grey darken-4"
                dark
                dense
                outlined />
            </validation-provider>
          </v-col>
          <v-col
            md="3"
            cols="4">
            <validation-provider
              v-slot="{ errors }"
              :rules="validations.playType"
              name="status"
              slim>
              <v-autocomplete
                v-model="form.playTypes"
                :items="params.playTypes"
                :error-messages="errors"
                item-text="label"
                item-value="value"
                :label="$t('attributes.tickets.play_types')"
                color="blue-grey"
                background-color="blue-grey darken-4"
                dark
                multiple
                dense
                outlined>
                <template #selection="{ index, item }">
                  <span
                    v-if="form.playTypes.length === 1"
                    class="white--text text-caption">
                    {{ item.label }}
                  </span>
                  <span
                    v-else-if="form.playTypes.length > 1 && index === 0"
                    class="white--text text-caption">
                    {{ form.playTypes.length }} selected
                  </span>
                </template>
              </v-autocomplete>
            </validation-provider>
          </v-col>
          <v-switch
            v-if="mode === 'admin'"
            v-model="form.pendingPayment"
            class="mt-6"
            color="info"
            dark
            :label="$t('views.tickets.monitor.pending_payment')" />
          <v-col
            cols="2">
            <v-btn
              color="blue-grey"
              background-color="blue-grey darken-4"
              block
              dark
              @click="fetchTickets">
              {{ $t("views.tickets.monitor.search") }}
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-btn-toggle
              v-model="filter.status"
              active-class="selected-status"
              group
              dark
              mandatory
              background-color="gray">
              <v-btn value="all">
                {{ $t("enumerations.ticketStatus.all") }}
              </v-btn>
              <v-btn :value="TicketStatus.pending">
                {{ $t("enumerations.ticketStatus.pending") }}
              </v-btn>
              <v-btn
                :value="TicketStatus.loser">
                {{ $t("enumerations.ticketStatus.loser") }}
              </v-btn>
              <v-btn :value="TicketStatus.winner">
                {{ $t("enumerations.ticketStatus.winner") }}
              </v-btn>
              <v-btn value="cancelled">
                {{ $t("enumerations.ticketStatus.cancelled") }}
              </v-btn>
            </v-btn-toggle>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            offset-sm="8"
            sm="4"
            offset-lg="9"
            lg="3">
            <v-text-field
              v-model="search"
              prepend-inner-icon="mdi-magnify"
              background-color="white"
              single-line
              outlined
              dense
              hide-details />
          </v-col>
          <v-col
            cols="12">
            <v-data-table
              id="tickets-table"
              :search="search"
              :custom-filter="ticketMonitorFilter"
              :headers="headers"
              :items="filteredTickets"
              item-key="name"
              dense
              class="elevation-1"
              hide-default-footer
              disable-pagination
              @click:row="handleRowClick">
              <template #item.gameId="{ item }">
                <span v-if="item.gameId === Game.roulette"> {{ $t("views.tickets.create.game_names.roulette") }}</span>
              </template>
              <template #item.processedPrizeToPay="{ item }">
                {{ item.processedPrizeToPay + item.jackpotPrize }}
                <span
                  v-if="item.jackpotPrize > 0"
                  class="jackpot-legend">JP</span>
              </template>
              <template #item.status="{ item }">
                <span>{{ getDisplayStatus(item) }}</span>
              </template>
            </v-data-table>
          </v-col>
          <!-- Ticket Preview -->
          <v-dialog
            v-model="showTicketPreview"
            max-width="550">
            <v-card>
              <v-card-text>
                <ticket-preview
                  :ticket="currentTicket"
                  show-cancel
                  @ticket-updated="handleTicketUpdated" />
              </v-card-text>
            </v-card>
          </v-dialog>
          <!-- Ticket preview end -->
        </v-row>
      </v-container>
    </validation-observer>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import {
  fetchTicketById,
  getAdminIndexParams,
  getAdminTickets,
  getPoolIndexParams,
  getPoolTickets,
} from "@/api/ticket.js";
import TicketStatus from "@/imported/TicketStatus.json";
import Game from "@/imported/Game.json";
import TicketPreview from "@/components/tickets/TicketPreview.vue";
import moment from "moment";
import MenuDatePicker from "@/components/inputs/MenuDatePicker.vue";

export default {
  name: "TicketMonitor",
  components: {
    MenuDatePicker,
    ValidationProvider,
    ValidationObserver,
    TicketPreview,
  },
  props: {
    mode: {
      type: String,
      required: false,
      default: "pool",
      validator: (value) => {
        return ["admin", "pool"].indexOf(value) !== -1;
      },
    },
  },
  data() {
    return {
      search: "",
      date: moment().
      format("YYYY-MM-DD"),
      validations: {
        playTypes: "required",
        dates: "required",
        bettingPool: "required",
      },
      form: {
        formattedDate: moment().
        format("YYYY-MM-DD"),
        playTypes: null,
        bettingPoolId: null,
        pendingPayment: false,
      },
      filter: {
        status: "all",
      },
      server: {
        tickets: [],
      },
      params: {
        playTypes: [],
        bettingPools: [],
      },
      TicketStatus,
      Game,
      showTicketPreview: false,
      currentTicket: null,
      currentPlays: [],
    };
  },
  computed: {
    headers() {
      return [
        {
          text: "#",
          value: "number",
          align: "center",
        },
        {
          text: this.$t("views.tickets.monitor.creation_date"),
          value: "createdAt",
          align: "center",
          filterable: false,
        },
        {
          text: this.$t("views.tickets.monitor.game"),
          value: "gameId",
          align: "center",
          filterable: false,
        },
        {
          text: this.$t("views.tickets.monitor.round"),
          value: "roundNumber",
          align: "center",
          filterable: false,
        },
        {
          text: this.$t("views.tickets.monitor.status"),
          value: "status",
          align: "center",
          filterable: false,
        },
        {
          text: this.$t("views.tickets.monitor.amount"),
          value: "amount",
          align: "center",
          filterable: false,
        },
        {
          text: this.$t("views.tickets.monitor.payout"),
          value: "processedPrizeToPay",
          align: "center",
          filterable: false,
        },
        {
          text: this.$t("views.tickets.monitor.cancellation_date"),
          value: "cancelDate",
          align: "center",
          filterable: false,
        },
      ];
    },
    filteredTickets() {
      if (this.filter.status === "all") {
        return this.server.tickets;
      } else if (this.filter.status === "cancelled") {
        return this.server.tickets.filter(t => t.cancelled);
      }

      return this.server.tickets.filter(t => t.status === this.filter.status && !t.cancelled);
    },
  },
  watch: {
    "form.pendingPayment"() {
      this.$refs.validationObserver.reset();
    },
  },
  mounted() {
    this.fetchParams().
    then(() => {
      if (this.mode === "pool") {
        this.fetchTickets();
      }
    });
  },
  methods: {
    ticketMonitorFilter(value, search, _item) {
      if (search !== null) {
        return value.includes(search);
      }

      return true;
    },
    allowedDates(value) {
      const momentObj = moment(value, "YYYY-MM-DD").
      startOf("day");
      const tomorrowDate = moment().
      startOf("day").
      add(1, "days");

      return momentObj.isBefore(tomorrowDate);
    },
    fetchParams() {
      const promise = this.mode === "pool"
        ?
        getPoolIndexParams()
        :
        getAdminIndexParams();
      return promise.then(response => {
        this.params = {
          ...response.data.params,
        };
      });
    },
    fetchTickets() {
      const submitForm = {
          date: this.date,
          playTypes: this.form.playTypes,
          pendingPayment: this.form.pendingPayment,
        },
        validationObserver = this.$refs.validationObserver;

      validationObserver.validate().
      then(formWasValid => {
        if (formWasValid) {
          const promise = this.mode === "pool"
            ?
            getPoolTickets(submitForm)
            :
            getAdminTickets({
              ...submitForm,
              bettingPoolId: this.form.bettingPoolId,
            });

          promise.then(response => {
            this.server.tickets = response.data.tickets;
          });
        }
      });
    },
    getDisplayStatus(ticket) {
      if (ticket.cancelled) {
        return this.$t("enumerations.ticketStatus.cancelled");
      } else if (ticket.paid) {
        return this.$t("enumerations.ticketStatus.paid");
      } else {
        switch (parseInt(ticket.status, 10)) {
          case TicketStatus.pending:
            return this.$t("enumerations.ticketStatus.pending");
          case TicketStatus.loser:
            return this.$t("enumerations.ticketStatus.loser");
          case TicketStatus.winner:
            return this.$t("enumerations.ticketStatus.winner");
          default:
            return "-";
        }
      }
    },
    handleRowClick(item) {
      fetchTicketById(item.id).
      then(response => {
        this.currentTicket = response.data.ticket;
        this.showTicketPreview = true;
      }).
      catch(() => {
        console.log("error handling here?");
      });
    },
    handleTicketUpdated(updatedTicket) {
      const index = this.server.tickets.findIndex(item => item.id === updatedTicket.id);

      this.$set(this.server.tickets, index, updatedTicket);
      this.showTicketPreview = false;
    },
  },
};
</script>

<style
  lang="scss"
  scoped>

::v-deep .theme--dark.v-label,
.v-input--is-focused,
.theme--dark.v-icon.black--text {
  color: white !important;
  caret-color: white !important;
  border-color: white;
}

#ticket-monitor-wrapper {
  width: 100%;
  height: 100%;
  background: #465f64 url("../../../public/img/backgrounds/glass.png") center no-repeat;
  background-position-y: 200px;
  background-size: 500px;
  overflow: hidden;
  opacity: 0.9;
}

#tickets-table {
  opacity: 0.9;
}

::v-deep #tickets-table table tbody tr {
  cursor: pointer;
}

::v-deep .v-btn-toggle--group > .v-btn.v-btn {
  .selected-status {
    background-color: #57717d !important;
  }

  color: white;
  font-weight: bolder;
  font-size: 1.2em;
}

.jackpot-legend {
  color: gold;
  font-weight: bold;
}
</style>
