import httpClient from "./httpClient";
import SaleIndexCategory from "@/imported/SaleIndexCategory.json";

const resource = "sales";

export function getParams() {
  return httpClient.get(`/${resource}/params`);
}

export function getDailySales(filter) {
  return httpClient.get(`/${resource}`, {
    params: {
      ...filter,
      category: SaleIndexCategory.daily
    }
  });
}

export function getWeeklySales(filter) {
  return httpClient.get(`/${resource}`, {
    params: {
      ...filter,
      category: SaleIndexCategory.weekly
    }
  });
}

export function getPoolDailySales(filter) {
  return httpClient.get(`/${resource}`, {
    params: {
      ...filter,
      category: SaleIndexCategory.pool
    }
  });
}

export function getPrintPoolDailySales(filter) {
  return httpClient.get(`/${resource}`, {
    params: {
      ...filter,
      category: SaleIndexCategory.printPool
    }
  });
}

export function getPoolWeeklySaleSummary(filter) {
  return httpClient.get(`/${resource}`, {
    params: { ...filter, category: SaleIndexCategory.poolWeekly }
  });
}

export function getPrintPoolWeeklySales(filter) {
  return httpClient.get(`/${resource}`, {
    params: { ...filter, category: SaleIndexCategory.printPoolWeekly }
  });
}

export function getSalesHistory(filter) {
  return httpClient.get(`/${resource}`, {
    params: {
      ...filter,
      category: SaleIndexCategory.history
    }
  });
}
