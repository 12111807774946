var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"id":"plays-table","headers":_vm.headers,"items":_vm.plays.value,"items-per-page":3,"centered":"","disable-pagination":"","disable-sort":"","disable-filtering":"","hide-default-footer":"","fixed-header":"","height":"30vh"},scopedSlots:_vm._u([{key:"header.amount",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),(_vm.divisionBet)?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary"}},'v-icon',attrs,false),on),[_vm._v(" mdi-link ")])]}}],null,true)},[_c('span',[_vm._v("Division bet")])]):_vm._e()]}},{key:"footer",fn:function(){return [_c('div',{attrs:{"id":"table-footer"}},[_vm._v(" "+_vm._s(_vm.$t("views.tickets.create.total", { amount: _vm.totalAmount }))+" ")])]},proxy:true},{key:"item.playType",fn:function(ref){
var item = ref.item;
return [(item.playType === _vm.PlayType.straight)?_c('div',[_c('v-chip',{attrs:{"color":item.color,"dark":""}},[_vm._v(" "+_vm._s(item.value)+" ")])],1):_vm._e(),(item.playType === _vm.PlayType.column)?_c('div',{staticClass:"selected-play-wrapper"},[_c('div',{staticClass:"modifier"},[_vm._v(" (2 - 1) "),_c('v-chip',{attrs:{"color":item.color,"dark":""}},[_vm._v(" "+_vm._s(item.value)+" ")])],1)]):_vm._e(),(item.playType === _vm.PlayType.dozen)?_c('div',{staticClass:"selected-play-wrapper"},[_c('div',{staticClass:"modifier"},[_vm._v(" "+_vm._s(_vm.dozenPlayPreviewText(item.value))+" ")])]):_vm._e(),(item.playType === _vm.PlayType.low)?_c('div',{staticClass:"selected-play-wrapper"},[_vm._v(" 1 - 18 ")]):_vm._e(),(item.playType === _vm.PlayType.high)?_c('div',{staticClass:"selected-play-wrapper"},[_vm._v(" 19 to 36 ")]):_vm._e(),(item.playType === _vm.PlayType.even)?_c('div',[_vm._v(" Even ")]):_vm._e(),(item.playType === _vm.PlayType.odd)?_c('div',[_vm._v(" Odd ")]):_vm._e(),(item.playType === _vm.PlayType.red)?_c('div',[_vm._v(" Red ")]):_vm._e(),(item.playType === _vm.PlayType.black)?_c('div',{staticClass:"selected-play-wrapper"},[_vm._v(" Black ")]):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }