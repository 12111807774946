<template>
  <div class="transparent-background">
    <portal to="navbar-right">
      <v-btn
        color="transparent"
        :ripple="false"
        elevation="0">
        <font-awesome-icon
          icon="user"
          class="mr-3" />
        {{ $store.getters.username }}
      </v-btn>
      <v-btn
        icon
        @click="duplicateTicketDialog.show = true">
        <font-awesome-icon icon="copy" />
      </v-btn>
      <v-btn
        icon
        @click="helpDialog.show = true">
        <font-awesome-icon icon="question" />
      </v-btn>
      <v-btn
        icon
        @click="salesDialog.show = true">
        <font-awesome-icon icon="chart-line" />
      </v-btn>
      <v-btn
        icon
        @click="linkRouletteDialog.show = true">
        <font-awesome-icon icon="external-link-alt" />
      </v-btn>
    </portal>
    <v-row
      no-gutters>
      <v-col
        cols="12">
        <round-information-header
          :round="form.currentRound" />
      </v-col>
    </v-row>
    <v-container
      id="container"
      class="fill-height"
      fluid>
      <!-- board -->
      <v-row>
        <v-col cols="12">
          <board @click-key="onBoardKeyClick" />
        </v-col>
      </v-row>
      <!-- table and play inputs -->
      <v-row id="plays-and-amount-row">
        <!-- table -->
        <v-col cols="6">
          <plays-table
            :plays="form.plays"
            :division-bet="form.divisionBet.value"
            @deletePlay="removePlay" />
        </v-col>
        <!-- Inputs -->
        <v-col cols="6">
          <v-row
            no-gutters
            class="input-width">
            <v-col cols="12">
              <!-- Selected play -->
              <v-row>
                <v-col cols="8">
                  <playing-area
                    ref="playingArea"
                    :digits="form.digits"
                    :amount="form.amount"
                    :round="form.currentRound"
                    :configuration="configuration"
                    @update:digits="form.digits.value = $event"
                    @update:amount="form.amount.value = $event"
                    @close-ticket="closeTicket"
                    @plays-parsed="addPlays"
                    @error="onError"
                    @open-duplicate-modal="duplicateTicketDialog.show=true"
                    @division-bet-toggle="form.divisionBet.value = !form.divisionBet.value"
                    @pay-ticket="payTicketDialog.show = true"
                    @clear-errors="clearErrors" />
                </v-col>
                <v-col cols="2">
                  <v-btn
                    color="green darken-3"
                    height="100%"
                    dark
                    block
                    :ripple="false"
                    @click="closeTicket">
                    <font-awesome-icon
                      icon="print"
                      size="2x" />
                  </v-btn>
                </v-col>
                <v-col cols="2">
                  <extend-round-button
                    v-if="configuration.value.allowExtendedRound"
                    :round="form.currentRound"
                    @click="extendRound" />
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="5"
                  class="text-center">
                  <recent-tickets
                    :tickets="roundTickets"
                    @cancel-ticket="onTicketCancel" />
                </v-col>
                <v-col>
                  <validation-summary :errors="validationErrors" />
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="3"
                  class="text-center">
                  <division-bet-toggle
                    :division-bet="form.divisionBet"
                    @update:division-bet="form.divisionBet.value = $event" />
                </v-col>
                <!-- Pay ticket -->
                <v-col cols="3">
                  <v-btn
                    color="primary"
                    @click="payTicketDialog.show = true">
                    <font-awesome-icon icon="dollar-sign" />
                    {{ $t("views.tickets.create.pay") }}
                  </v-btn>
                  <pay-ticket-dialog
                    :show="payTicketDialog.show"
                    @paid="onTicketPayment"
                    @dismiss="payTicketDialog.show = false" />
                </v-col>
                <v-col cols="6">
                  <latest-winner-tickets :tickets="latestWinnerTickets" />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <cancel-ticket-dialog
        :show="cancelTicketDialog.show"
        :ticket-id="cancelTicketDialog.id"
        @dismiss="onTicketCancelDismiss"
        @cancel="onTicketCancelConfirm" />
      <v-overlay
        absolute
        :value="form.currentRound.value.overlay">
        <p class="text-h2 text-uppercase font-weight-black">
          {{ $t("views.tickets.create.no_more_bets") }}
        </p>
      </v-overlay>
      <help-dialog
        :show="helpDialog.show"
        @dismiss="helpDialog.show = false" />
      <pool-sales-dialog
        :show="salesDialog.show"
        @dismiss="salesDialog.show = false" />
      <link-screen-dialog
        :show="linkRouletteDialog.show"
        @dismiss="linkRouletteDialog.show = false" />
      <duplicate-ticket-dialog
        :show="duplicateTicketDialog.show"
        @duplicate-ticket="duplicatePlays"
        @dismiss="duplicateTicketDialog.show = false" />
    </v-container>
  </div>
</template>

<script>
import Board from "@/components/games/roulette/PlayingBoard.vue";
import PlaysTable from "@/components/games/roulette/PlaysTable.vue";
import { create as createTicket, extendRound, getCreateParams } from "@/api/ticket.js";
import PrintUtils from "@/helpers/PrintUtils";
import PayTicketDialog from "@/components/tickets/PayTicketDialog.vue";
import PlayingArea from "@/components/tickets/PlayingArea.vue";
import RoundInformationHeader from "@/components/games/roulette/RoundInformationHeader.vue";
import ValidationSummary from "@/components/games/roulette/ValidationSummary";
import ExtendRoundButton from "@/components/games/roulette/ExtendRoundButton.vue";
import RecentTickets from "@/components/games/roulette/RecentTickets.vue";
import DivisionBetToggle from "@/components/games/roulette/DivisionBetToggle.vue";
import CancelTicketDialog from "@/components/tickets/CancelTicketDialog.vue";
import moment from "moment-timezone";
import numeral from "numeral";
import LatestWinnerTickets from "@/components/tickets/LatestWinnerTickets.vue";
import HelpDialog from "@/components/tickets/roulette/HelpDialog.vue";
import PoolSalesDialog from "@/components/tickets/PoolSalesDialog.vue";
import LinkScreenDialog from "@/components/tickets/LinkScreenDialog.vue";
import { createNamespacedHelpers } from "vuex";
import { MODULE_NAME as CLOCK_MODULE_NAME } from "../../../store/modules/clock";
import DuplicateTicketDialog from "@/components/tickets/DuplicateTicketDialog";
import { duplicatePlays } from "@/utils/roulette/parse";

const { mapState: mapClockState } = createNamespacedHelpers(CLOCK_MODULE_NAME);

export default {
  name: "Roulette",
  components: {
    DuplicateTicketDialog,
    LinkScreenDialog,
    PoolSalesDialog,
    HelpDialog,
    LatestWinnerTickets,
    CancelTicketDialog,
    DivisionBetToggle,
    RecentTickets,
    ExtendRoundButton,
    ValidationSummary,
    RoundInformationHeader,
    PlayingArea,
    Board,
    PlaysTable,
    PayTicketDialog,
  },
  data() {
    return {
      validationErrors: {
        value: [],
      },
      form: {
        ticketClosingInProgress: false,
        plays: {
          value: [],
        },
        digits: {
          value: null,
        },
        amount: {
          value: null,
        },
        divisionBet: {
          value: false,
        },
        currentRound: {
          value: {
            id: null,
            sequence: null,
            startTimeInMillis: null,
            closeTimeInMillis: null,
            extended: null,
            timeLeft: null,
            noMoreBets: false,
            overlay: false,
          },
        },
      },
      roundTickets: {
        value: [],
      },
      latestWinnerTickets: {
        value: [],
      },
      configuration: {
        value: {
          allowExtendedRound: false,
          maximumAmountPerPlay: null,
        },
      },
      clockInterval: null,
      payTicketDialog: {
        show: false,
      },
      cancelTicketDialog: {
        show: false,
        id: {
          value: null,
        },
      },
      helpDialog: {
        show: false,
      },
      salesDialog: {
        show: false,
      },
      linkRouletteDialog: {
        show: false,
      },
      duplicateTicketDialog: {
        show: false,
      },
    };
  },
  computed: {
    ...mapClockState({
      currenTime: state => state.time,
      timeZone: state => state.timeZone,
    }),
  },
  watch: {
    "currenTime": "clockTick",
  },
  channels: {
    PosChannel: {
      received(payload) {
        if (payload.type === "ROUND_START") {
          const { round } = payload;
          this.setCurrentRound(round);
        } else if (payload.type === "ROUND_COMPLETED") {
          this.prepareScreenForNextRound();
        }
      },
    },
  },
  created() {
    this.fetchParams();
    PrintUtils.setup();
  },
  mounted() {
    this.$refs.playingArea.focusDigits();
    this.$cable.subscribe({
      channel: "PosChannel",
    });
  },
  beforeDestroy() {
    clearInterval(this.clockInterval);
  },
  methods: {
    duplicatePlays(serverSidePlays) {
      this.addPlays({ plays: duplicatePlays(serverSidePlays), creationMethod: "duplication" });
    },
    onTicketPayment(payload) {
      const { ticket } = payload;
      const index = this.latestWinnerTickets.value.findIndex(t => t.id === ticket.id);

      this.latestWinnerTickets.value.splice(index, 1);
    },
    onTicketCancelConfirm(payload) {
      this.cancelTicketDialog.show = false;
      this.cancelTicketDialog.id.value = null;
      const index = this.roundTickets.value.findIndex(t => t.id === payload.id);

      this.roundTickets.value.splice(index, 1);
    },
    onTicketCancelDismiss() {
      this.cancelTicketDialog.show = false;
      this.cancelTicketDialog.id.value = null;
    },
    onTicketCancel(payload) {
      const { id } = payload;

      this.cancelTicketDialog.id.value = id;
      this.$nextTick(() => {
        this.cancelTicketDialog.show = true;
      });
    },
    prepareScreenForNextRound() {
      const round = this.form.currentRound.value;

      this.cancelTicketDialog.id.value = null;
      this.roundTickets.value = [];
      this.$set(round, "id", null);
      this.$set(round, "sequence", null);
      this.$set(round, "startTimeInMillis", null);
      this.$set(round, "closeTimeInMillis", null);
      this.$set(round, "extended", null);
      this.$set(round, "noMoreBets", false);
      this.$set(round, "overlay", false);
      this.$emit("round-complete");

      this.fetchParams();
      this.$refs.playingArea.focusDigits();
    },
    fetchParams(options = {}) {
      return getCreateParams().then(response => {
        const {
          tickets,
          round,
          latestWinnerTickets,
          configuration,
        } = response.data.params;

        this.roundTickets.value = tickets;
        this.latestWinnerTickets.value = latestWinnerTickets;
        this.setConfiguration(configuration);
        if (round) {
          this.setCurrentRound(round);
          if (round.noMoreBets) {
            this.$emit("round-in-progress");
          }
        } else if(options.isStuck) {
          this.prepareScreenForNextRound();
        }
      });
    },
    setConfiguration({
                       maximumAmountPerPlay,
                       allowExtendedRound,
                     }) {
      this.configuration.value = {
        maximumAmountPerPlay,
        allowExtendedRound,
      };
    },
    onError(errors) {
      this.validationErrors.value = errors;
    },
    clearErrors() {
      if (this.validationErrors.value.length > 0) {
        this.validationErrors.value = [];
      }
    },
    clockTick(currentTime) {
      const round = this.form.currentRound.value;

      if (round.id) {
        const closeTime = moment(round.closeTimeInMillis).tz(this.timeZone),
          currentTimeInMillis = currentTime,
          timeLeftDuration = moment.duration(closeTime.diff(currentTimeInMillis)),
          formattedTimeLeft = numeral(timeLeftDuration.asSeconds()).format("00:00:00");

        if (timeLeftDuration.asSeconds() >= 0) {
          this.$set(round, "timeLeft", formattedTimeLeft.slice(2, formattedTimeLeft.length));
        } else if (round.noMoreBets === false) {
          this.$set(round, "timeLeft", null);
          this.updateRoundStatus();
          setTimeout(this.handleNoMoreBetsTimeout, 20000);
        }
      }
    },
    handleNoMoreBetsTimeout() {
      if (this.form.currentRound.value.overlay) {
        this.fetchParams({isStuck: true}).then(() => {
          this.$nextTick(() => {
            if (this.form.currentRound.value.overlay) {
              setTimeout(this.handleNoMoreBetsTimeout, 5000);
            }
          });
        }).catch(() => {
          setTimeout(this.handleNoMoreBetsTimeout, 5000);
        });
      }
    },
    addPlays(payload) {
      const {
        plays,
        creationMethod,
      } = payload;

      this.form.plays.value.unshift(...plays);
      if (this.form.divisionBet.value) {
        const amountForEachPlay = this.form.amount.value / this.form.plays.value.length,
          roundedAmount = Math.round((amountForEachPlay + Number.EPSILON) * 100) / 100;
        this.form.plays.value.forEach(play => {
          play.amount = roundedAmount;
        });
      }

      this.$refs.playingArea.focusDigits();
    },
    removePlay(index) {
      this.form.plays.value.splice(index, 1);
    },
    onBoardKeyClick(payload) {
      this.form.digits.value = payload.play;
      this.$refs.playingArea.focusAmount();
    },
    closeTicket() {
      if (this.form.plays.value.length === 0) {
        this.onError([this.$t("validations.others.tickets.ticket_without_plays")]);
        return;
      }

      if (this.form.currentRound.value.noMoreBets) {
        this.onError([this.$t("validations.others.tickets.current_round_no_more_bets")]);
        return;
      }

      if (this.ticketClosingInProgress) {
        setTimeout(this.closeTicket, 100);

        return;
      }

      this.ticketClosingInProgress = true;

      const submitForm = {
        divisionBet: this.form.divisionBet.value,
        playsAttributes: this.form.plays.value,
      };

      createTicket(submitForm).then(response => {
        const {
          ticket,
          documentLines,
        } = response.data;
        this.ticketClosingInProgress = false;
        this.setCurrentRound(ticket.round);
        this.prepareScreenForNextTicket();
        PrintUtils.printDocumentLines(documentLines);

        const newTicket = {
          id: ticket.id,
          sequence: ticket.sequence,
          amount: ticket.amount,
        };

        this.roundTickets.value = [
          newTicket,
          ...this.roundTickets.value.slice(0, 9),
        ];
      }).catch(error => {
        this.ticketClosingInProgress = false;
        if (error?.response?.data?.failures) {
          this.onError(error.response.data.failures);
        }
      });
    },
    prepareScreenForNextTicket() {
      this.clearErrors();
      this.form.digits.value = null;
      this.form.amount.value = null;
      this.form.plays.value.splice(0, this.form.plays.value.length);
      this.$nextTick(() => {
        this.$refs.playingArea.focusDigits();
      });
    },
    setCurrentRound(ongoingRound) {
      this.form.currentRound.value = {
        id: ongoingRound.id,
        sequence: ongoingRound.sequence,
        startTimeInMillis: ongoingRound.startTimeInMillis,
        closeTimeInMillis: ongoingRound.closeTimeInMillis,
        extended: ongoingRound.extended,
        noMoreBets: ongoingRound.noMoreBets,
        overlay: ongoingRound.noMoreBets,
      };
    },
    updateRoundStatus() {
      this.form.currentRound.value.noMoreBets = true;
      this.form.currentRound.value.overlay = true;
      this.$emit("round-in-progress");
    },
    extendRound() {
      const roundId = this.form.currentRound.value.id;

      if (roundId) {
        extendRound(roundId).then(response => {
          const round = response.data.round;
          this.setCurrentRound(round);
        }).catch(error => {
          if (error?.response?.data?.failures) {
            this.onError(error.response.data.failures);
          }
        });
      } else {
        this.onError([this.$t("validations.others.tickets.round_not_in_progress")]);
      }
    },
  },
};
</script>

<style
  lang="scss"
  scoped>

.info-wrapper {
  position: absolute;
  left: 0;
}

#plays-and-amount-row {
  margin-top: 10px;
  background-color: transparent;
}

.input-background {
  background: #1d471d;
  border: 1px solid white;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  color: white;
  line-height: 100%;
  font-size: 40px;
  text-transform: uppercase;
}

::v-deep .input-background .v-input__slot {
  background: #1d471d;
}

.selected-play {
  height: 43px;
  width: 80px;
  border-radius: 70%;
  color: white;
  font-size: 2em;
  font-weight: bolder;
  text-align: center;

  &.red-colorant {
    background: red;
  }

  &.black-colorant {
    background: #000001;
  }
}

.selected-play-wrapper {
  div {
    display: inline-block;
    height: 100%;

    &.modifier {
      font-size: 2em;
      margin-right: 10px;
    }
  }
}

.input-width {
  height: 3em;
}

::v-deep .custom-dark-alert {
  background-color: rgba(255, 255, 255, 0.1) !important;
  opacity: 1;
  padding: 10px;
  height: 3.5em;
}

::v-deep .v-alert--text:before {
  opacity: 1 !important;
  background-color: transparent !important;
}

div.colored-diamond {
  border: 3px solid white;
  height: 45px;
  width: 45px;
  display: inline-block;
  transform: scaley(0.6) rotate(45deg);
  font-size: 1em;
  margin-left: 5%;

  &.red-colorant {
    background-color: red;
  }

  &.black-colorant {
    background-color: black;
  }
}

::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

#container, .transparent-background {
  background: transparent;
}

::v-deep .centered-input > > > label {
  width: 100%;
  text-align: center;
}

.common-amount {
  background-color: #16471f;
  border: 1px solid white;
  border-radius: 5px;
  cursor: pointer;

  span {
    font-size: 1.5em;
  }
}

.text--white {
  color: white;
}

</style>
