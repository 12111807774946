const LETTER_B = 'b',
  LETTER_C = 'c',
  LETTER_D = 'd',
  LETTER_E = 'e',
  LETTER_F = 'f',
  LETTER_L = 'l',
  LETTER_N = 'n',
  LETTER_O = 'o',
  LETTER_P = 'p',
  LETTER_Q = 'q',
  LETTER_U = 'u',
  DOT = '.',
  SLASH = '/',
  MINUS = '-',
  ASTERISK = '*',
  PLUS = '+',
  ARROW_UP = 'arrowup',
  ARROW_LEFT = 'arrowleft',
  ARROW_RIGHT = 'arrowright',
  ENTER = 'enter',
  NUMBER_0 = '0',
  NUMBER_1 = '1',
  NUMBER_2 = '2',
  NUMBER_3 = '3',
  NUMBER_4 = '4',
  NUMBER_5 = '5',
  NUMBER_6 = '6',
  NUMBER_7 = '7',
  NUMBER_8 = '8',
  NUMBER_9 = '9',
  BACKSPACE = 'backspace',
  DELETE = 'delete',
  TAB = 'tab';

export {
  LETTER_B,
  LETTER_C,
  LETTER_D,
  LETTER_E,
  LETTER_F,
  LETTER_L,
  LETTER_N,
  LETTER_O,
  LETTER_P,
  LETTER_Q,
  LETTER_U,
  DOT,
  SLASH,
  MINUS,
  ASTERISK,
  PLUS,
  ARROW_UP,
  ARROW_LEFT,
  ARROW_RIGHT,
  ENTER,
  NUMBER_0,
  NUMBER_1,
  NUMBER_2,
  NUMBER_3,
  NUMBER_4,
  NUMBER_5,
  NUMBER_6,
  NUMBER_7,
  NUMBER_8,
  NUMBER_9,
  BACKSPACE,
  DELETE,
  TAB
};
