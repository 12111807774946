<template>
  <v-menu
    ref="menu"
    v-model="showMenu"
    :return-value="currentSelection"
    v-bind="menuOptions"
    @update:return-value="$emit('input', currentSelection)">
    <template #activator="{ on, attrs }">
      <v-text-field
        :value="value"
        readonly
        v-bind="{...attrs, ...inputOptions}"
        v-on="on" />
    </template>
    <v-date-picker
      :value="currentSelection"
      scrollable
      v-bind="datePickerOptions"
      @input="onDatePickerInput">
      <template
        v-if="confirmSelection"
        #default>
        <v-spacer />
        <v-btn
          text
          color="primary"
          @click="onCancel">
          Cancel
        </v-btn>
        <v-btn
          text
          color="primary"
          @click="onConfirm">
          OK
        </v-btn>
      </template>
    </v-date-picker>
  </v-menu>
</template>

<script>

export default {
  name: "MenuDatePicker",
  props: {
    value: {
      type: String,
      required: true,
    },
    confirmSelection: {
      type: Boolean,
      default: false,
    },
    inputOptions: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    menuOptions: {
      type: Object,
      required: false,
      default: () => ({
        transition: "scale-transition",
        offsetY: true,
        minWidth: "auto",
      }),
    },
    datePickerOptions: {
      type: Object,
      required: false,
      default: () => ({})
    }
  },
  data() {
    return {
      currentSelection: this.value,
      showMenu: false,
    };
  },
  methods: {
    onDatePickerInput(e) {
      if (this.confirmSelection) {
        this.currentSelection = e;
      } else {
        this.currentSelection = e;
        this.showMenu = false;
      }
    },
    onCancel() {
      this.showMenu = false;
      this.currentSelection = this.value;
    },
    onConfirm() {
      this.$refs.menu.save(this.value);
    },
  },
};
</script>

<style scoped>

</style>
