<template>
  <v-dialog
    ref="dialog"
    v-model="showDialog"
    :return-value="currentSelection"
    v-bind="dialogOptions"
    @update:return-value="$emit('input', currentSelection)">
    <template #activator="{ on, attrs }">
      <v-text-field
        :value="value"
        readonly
        v-bind="{...attrs, ...inputOptions}"
        v-on="on" />
    </template>
    <v-date-picker
      :value="currentSelection"
      scrollable
      @input="onDatePickerInput">
      <template
        v-if="confirmSelection"
        #default>
        <v-spacer />
        <v-btn
          text
          color="primary"
          @click="onCancel">
          Cancel
        </v-btn>
        <v-btn
          text
          color="primary"
          @click="onConfirm">
          OK
        </v-btn>
      </template>
    </v-date-picker>
  </v-dialog>
</template>

<script>
// Todo: Pass OK and Cancel text as props

export default {
  name: "DialogDatePicker",
  props: {
    value: {
      type: String,
      required: true,
    },
    confirmSelection: {
      type: Boolean,
      default: false,
    },
    inputOptions: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    dialogOptions: {
      type: Object,
      required: false,
      default: () => ({
        persistent: false,
        width: "290px",
      }),
    },
  },
  data() {
    return {
      currentSelection: this.value,
      showDialog: false,
    };
  },
  methods: {
    onDatePickerInput(e) {
      if (this.confirmSelection) {
        this.currentSelection = e;
      } else {
        this.currentSelection = e;
        this.showDialog = false;
      }
    },
    onCancel() {
      this.showDialog = false;
      this.currentSelection = this.value;
    },
    onConfirm() {
      this.$refs.dialog.save(this.value);
    },
  },
};
</script>

<style scoped>

</style>
