<template>
  <div class="validation-summary" v-if="errors.value.length > 0">
    <ul>
      <li
        v-for="(error, index) in errors.value"
        :key="index">
        {{ error }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "ValidationSummary",
  props: {
    errors: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped>
.validation-summary {
  overflow-y: auto;
  max-height: 130px;
  padding-left: 20px;
  border: 1px solid red;
  color: #c52020;
  background: #FCC;
  margin: 0;
  border-radius: 4px;
}
</style>
