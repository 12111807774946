import { parseInt, replaceAll } from "./utils.js";
import PlayType from "../../imported/PlayType.json";
import { areAllCharactersNumbers, areAllCharactersTheSame } from "@/utils/roulette/validation.js";

const SPECIAL_CHARS_MATCHER = /[+\-.]/gu;

let localId = 0;

export function getNextLocalId() {
  localId += 1;

  return localId;
}

function isColumnPlay(playStringLoweredCase, number) {
  const validDozenColumns = ["1", "2", "3"];

  return playStringLoweredCase.startsWith(".") && validDozenColumns.includes(number);
}

function isDozenPlay(playStringLoweredCase, appendix) {
  const validAppendix = ["1", "2", "3"];

  return playStringLoweredCase.length === 4 && playStringLoweredCase.startsWith("12") && playStringLoweredCase.includes("+") && validAppendix.includes(appendix);
}

function isRedPlay(playStringLoweredCase) {
  return playStringLoweredCase === "+";
}

function isBlackPlay(playStringLoweredCase) {
  return playStringLoweredCase === "-";
}

function isLowPlay(playStringLoweredCase, rawNumberFromInput) {
  return playStringLoweredCase.length === 2 && rawNumberFromInput === "1" && playStringLoweredCase.endsWith("-");
}

function isHighPlay(playStringLoweredCase, rawNumberFromInput) {
  return playStringLoweredCase.length === 2 && rawNumberFromInput === "2" && playStringLoweredCase.endsWith("-");
}

function isEvenPlay(playStringLoweredCase) {
  return areAllCharactersTheSame(playStringLoweredCase) && playStringLoweredCase.startsWith("+");
}

function isOddPlay(playStringLoweredCase) {
  return areAllCharactersTheSame(playStringLoweredCase) && playStringLoweredCase.startsWith("-");
}

export function duplicatePlays(serverSidePlays) {
  const playsToMake = [];

  serverSidePlays.forEach(p => {
    const jsonPlay = {
      localId: getNextLocalId(),
      value: p.value,
      playType: p.playTypeId,
      amount: parseFloat(p.amount),
      payout: parseFloat(p.playTypePayout),
    };
    playsToMake.push(jsonPlay);
  });

  return playsToMake;
}

export function parse(playString, amount) {
  const playStringLoweredCase = playString.toLowerCase(),
    endingCharacter = playStringLoweredCase[playStringLoweredCase.length - 1],
    playsToMake = [];
  let sections = [playStringLoweredCase],
    rawNumberFromInput = null,
    appendix = null,
    number = null,
    jsonPlay = null;

  if (!endingCharacter) {
    return [];
  }

  sections = getSections(playStringLoweredCase);
  rawNumberFromInput = replaceAll(sections[0], SPECIAL_CHARS_MATCHER, "");
  appendix = sections[1] || "";
  number = parseInt(rawNumberFromInput, 10);

  if (isColumnPlay(playStringLoweredCase, rawNumberFromInput)) {
    jsonPlay = {
      localId: getNextLocalId(),
      value: number,
      playType: PlayType.column,
      amount: amount,
      payout: 3 * amount,
    };
    playsToMake.push(jsonPlay);
  } else if (isDozenPlay(playStringLoweredCase, appendix)) {
    jsonPlay = {
      localId: getNextLocalId(),
      value: parseInt(appendix, 10),
      playType: PlayType.dozen,
      amount: amount,
      payout: 3 * amount,
    };
    playsToMake.push(jsonPlay);
  } else if (isRedPlay(playStringLoweredCase)) {
    jsonPlay = {
      localId: getNextLocalId(),
      value: null,
      playType: PlayType.red,
      amount: amount,
      payout: 2 * amount,
    };
    playsToMake.push(jsonPlay);
  } else if (isBlackPlay(playStringLoweredCase)) {
    jsonPlay = {
      localId: getNextLocalId(),
      value: null,
      playType: PlayType.black,
      amount: amount,
      payout: 2 * amount,
    };
    playsToMake.push(jsonPlay);
  } else if (isLowPlay(playStringLoweredCase, rawNumberFromInput)) {
    jsonPlay = {
      localId: getNextLocalId(),
      value: null,
      playType: PlayType.low,
      amount: amount,
      payout: 2 * amount,
    };
    playsToMake.push(jsonPlay);
  } else if (isHighPlay(playStringLoweredCase, rawNumberFromInput)) {
    jsonPlay = {
      localId: getNextLocalId(),
      value: null,
      playType: PlayType.high,
      amount: amount,
      payout: 2 * amount,
    };
    playsToMake.push(jsonPlay);
  } else if (isEvenPlay(playStringLoweredCase)) {
    jsonPlay = {
      localId: getNextLocalId(),
      value: null,
      playType: PlayType.even,
      amount: amount,
      payout: 2 * amount,
    };
    playsToMake.push(jsonPlay);
  } else if (isOddPlay(playStringLoweredCase)) {
    jsonPlay = {
      localId: getNextLocalId(),
      value: null,
      playType: PlayType.odd,
      amount: amount,
      payout: 2 * amount,
    };
    playsToMake.push(jsonPlay);
  } else if (areAllCharactersNumbers(playStringLoweredCase)) {
    jsonPlay = {
      localId: getNextLocalId(),
      value: number,
      playType: PlayType.straight,
      amount: amount,
      payout: 35 * amount,
    };
    playsToMake.push(jsonPlay);
  }

  return playsToMake;
}

function getSections(playStringLoweredCase) {
  if (playStringLoweredCase.includes("+")) {
    return playStringLoweredCase.split("+");
  }

  return [playStringLoweredCase];
}
