<template>
  <v-row>
    <v-col cols="12">
      <v-row>
        <v-col class="text-center pt-6">
          <h3>
            {{ ticket.number }}
            <font-awesome-icon
              v-if="ticket.paid"
              :title="$t('views.tickets.monitor.paid_by_message', { user: ticket.paidBy, date: ticket.paymentDate })"
              class="paid-icon dollar"
              icon="dollar-sign" />
          </h3>
          <v-tooltip
            v-if="ticket.divisionBet"
            right>
            <template #activator="{on, attrs}">
              <v-icon
                color="primary"
                v-bind="attrs"
                v-on="on">
                mdi-link
              </v-icon>
            </template>
            <span>Division bet</span>
          </v-tooltip>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col
          cols="6"
          class="text-center">
          <div>
            {{ $t("views.tickets.monitor.round") }}
          </div>
          <div>
            <span class="font-weight-bold">{{ ticket.roundNumber }}</span>
          </div>
        </v-col>
        <v-col
          cols="6"
          class="text-center">
          <div>
            {{ $t("views.tickets.monitor.creation_date") }}
          </div>
          <div>
            <span class="font-weight-bold">{{ ticket.createdAt }}</span>
          </div>
        </v-col>
        <v-col
          v-if="ticket.cancelled"
          cols="6"
          class="text-center">
          <div>
            {{ $t("views.tickets.monitor.cancellation_date") }}
          </div>
          <div>
            <span class="font-weight-bold">{{ ticket.cancelDate }}</span>
          </div>
        </v-col>
      </v-row>
      <v-row />
      <v-row
        no-gutters
        class="mb-5">
        <v-col
          cols="6"
          class="text-center">
          {{ $t("views.tickets.monitor.amount") }} <br>
          <span class="font-weight-bold">{{ $formatter.asCurrency(ticket.amount) }}</span>
        </v-col>
        <v-col
          v-if="ticket.jackpot"
          cols="6"
          class="text-center">
          {{ $t("views.tickets.monitor.jackpot") }} <br>
          <span class="font-weight-bold">{{ $formatter.asCurrency(ticket.jackpotPrize) }}</span>
        </v-col>
        <v-col
          v-if="ticket.processedPrizeToPay"
          :cols="ticket.jackpot ? '12' : '6'"
          class="text-center font-weight-bold">
          {{ $t("views.tickets.monitor.total_payout") }} <br>
          <span class="font-weight-bold">{{ $formatter.asCurrency(ticket.processedPrizeToPay + ticket.jackpotPrize) }}</span>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col>
          <play-legend />
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col>
          <v-simple-table dense>
            <template #default>
              <thead>
                <tr>
                  <th>{{ $t("views.tickets.monitor.play") }}</th>
                  <th>{{ $t("views.tickets.monitor.amount") }}</th>
                  <th>{{ $t("views.tickets.monitor.payout") }}</th>
                  <th>{{ $t("views.tickets.monitor.paid") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="play in ticket.plays"
                  :key="play.id"
                  :class="statusColor(play.status)">
                  <td>
                    {{ play.playType }}
                    <template v-if="play.value">
                      (#{{ play.value }})
                    </template>
                  </td>
                  <td>{{ $formatter.asCurrency(play.amount) }}</td>
                  <td>
                    {{ $formatter.asCurrency(play.processedPrizeToPay) }}
                  </td>
                  <td>
                    <v-icon
                      v-if="play.paid"
                      class="paid-icon winner">
                      mdi-check
                    </v-icon>
                    <v-icon
                      v-else
                      class="paid-icon">
                      mdi-close
                    </v-icon>
                  </td>
                </tr>
                <tr
                  v-for="play in playsWithJackpot"
                  :key="play.id"
                  class="bg-gold">
                  <td>
                    {{ play.playType }}
                    <template v-if="play.value">
                      (#{{ play.value }})
                    </template>
                    - {{ $t("views.tickets.monitor.jackpot") }}
                  </td>
                  <td>{{ $formatter.asCurrency(play.amount) }}</td>
                  <td>
                    {{ $formatter.asCurrency(play.jackpotPrize) }}
                  </td>
                  <td>
                    <v-icon
                      v-if="play.paid"
                      class="paid-icon winner">
                      mdi-check
                    </v-icon>
                    <v-icon
                      v-else
                      class="paid-icon">
                      mdi-close
                    </v-icon>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import PlayStatus from "@/imported/PlayStatus.json";
import PlayLegend from "@/components/tickets/PlayLegend.vue";

export default {
  name: "TicketPreview",
  components: {
    PlayLegend,
  },
  props: {
    ticket: {
      type: Object,
      required: true,
    },
  },
  computed: {
    playsWithJackpot(){
      return this.ticket.plays.filter(p => p.jackpotPrize > 0);
    }
  },
  methods: {
    statusColor(statusId) {
      if (statusId === PlayStatus.winner) {
        return "success";
      } else if (statusId === PlayStatus.loser) {
        return "danger";
      }
    },
  }
};
</script>
<style scoped lang="scss">
.paid-icon {
  color: black;

  &.dollar {
    color: green;
  }
}
.bg-gold {
  background-color: gold;
}
</style>
