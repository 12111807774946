<template>
  <div
    id="results"
    class="transparent-background">
    <v-container
      background-color="blue-grey">
      <v-row
        class="mt-3">
        <v-col
          cols="4">
          <dialog-date-picker
            v-model="date"
            :input-options="{
              'prepend-icon': 'mdi-calendar',
              'dark': true,
              'outlined': true,
              'backgroundColor': 'blue-grey darken-4',
              'label': $t('views.tickets.monitor.date')
            }" />
        </v-col>
        <v-col
          cols="2">
          <v-btn
            color="blue-grey"
            background-color="blue-grey darken-4"
            block
            height="4em"
            dark
            @click="fetchResults">
            {{ $t("views.tickets.monitor.search") }}
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12">
          <v-dialog
            v-model="showTickets"
            max-width="500px">
            <template #activator="{on, attrs}">
              <v-data-table
                id="tickets-table"
                :headers="headers"
                :items="results"
                item-key="name"
                class="elevation-1"
                hide-default-footer
                disable-pagination>
                <template #item.prizes="{item}">
                  <v-btn
                    v-if="item.prizes > 0"
                    plain
                    v-bind="attrs"
                    v-on="on"
                    @click="currentTickets = item.tickets">
                    {{ item.prizes }}
                  </v-btn>
                  <span v-else>{{ item.prizes || '-' }}</span>
                </template>
              </v-data-table>
            </template>
            <v-card>
              <v-card-title class="headline text-center">
                Tickets
              </v-card-title>
              <v-card-text>
                <v-simple-table>
                  <template #default>
                    <thead>
                      <tr>
                        <th>{{ $t("views.roulette.results.tableColumns.ticket_number") }}</th>
                        <th>{{ $t("views.roulette.results.tableColumns.prize") }}</th>
                        <th>{{ $t("views.roulette.results.tableColumns.date") }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="ticket in currentTickets"
                        :key="ticket.id">
                        <td>{{ ticket.number }}</td>
                        <td>{{ ticket.processedPrizeToPay }}</td>
                        <td>{{ ticket.createdAt }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn
                  color="primary"
                  text
                  @click="showTickets = false">
                  {{ $t("common.close") }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { getReports } from "@/api/result.js";
import DialogDatePicker from "@/components/inputs/DialogDatePicker.vue";

import moment from "moment";

export default {
  name: "Result",
  components: { DialogDatePicker },
  data() {
    return {
      date: moment().
        format("YYYY-MM-DD"),
      results: [],
      showTickets: false,
      currentTickets: [],
    };
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t("views.roulette.results.tableColumns.round_number"),
          value: "roundNumber",
          align: "center",
        },
        {
          text: this.$t("views.roulette.results.tableColumns.result"),
          value: "result",
          align: "center",
        },
        {
          text: this.$t("views.roulette.results.tableColumns.prize"),
          value: "prizes",
          align: "center",
        },
        {
          text: this.$t("views.roulette.results.tableColumns.turn_time"),
          value: "turnTime",
          align: "center",
        },
      ];
    },
  },
  methods: {
    fetchResults() {
      getReports(this.date).
        then(response => {
          this.results = response.data.results;
        });
    },
  },
};
</script>

<style scoped>
#results {
  width: 100%;
  height: 100%;
  background: #465f64 url("../../../public/img/backgrounds/glass.png") center no-repeat;
  background-position-y: 200px;
  background-size: 500px;
  overflow: hidden;
  opacity: 0.9;
}
</style>
