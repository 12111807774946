/* eslint-disable */
import PrintMode from '../imported/PrintMode.json';
import Barcode from '../../public/javascript/jquery-barcode';

export default {
  setup(bettingPool) {
    if (bettingPool && bettingPool.printMode.id === PrintMode.generic) {
      var ws = new WebSocket('ws://127.0.0.1:8080');
      ws.onopen = function () {
        console.log('WS Connected');
      };
      ws.onerror = function (event) {
        if (!navigator.onLine) {
        } else if (ws.readyState === ws.CLOSED) {
          window.error = event;
        }
      };
      ws.onclose = function (event) {
        window.closeEvent = event;
      };
    }
    var chr,
      extractWordBlocksFromDocumentLine;
    window.printing || (window.printing = {});
    window.printing.data = [];
    window.printing.initializePrinter = function () {
      return window.printing.data.push(window.printing.chars.INIT);
    };

    window.printing.activateHeaderStyle = function () {
      return window.printing.data.push('\u001b!\b');
    };

    window.printing.activateStandardStyle = function () {
      return window.printing.data.push(chr(27) + chr(33) + chr(1));
    };

    window.printing.deactivateFontStyle = function () {
      return window.printing.data.push(chr(27) + chr(33) + 'D');
    };

    window.printing.activateHeadingFont = function () {
      return window.printing.data.push('\u001b!');
    };

    chr = function (asciiCode) {
      return String.fromCharCode(asciiCode);
    };

    window.printing.cutPaper = function () {
      return window.printing.data.push(chr(27) + chr(105));
    };

    window.printing.activateBold = function () {
      return window.printing.data.push(chr(27) + chr(69) + 'C');
    };

    window.printing.deactivateBold = function () {
      return window.printing.data.push(chr(27) + chr(69) + 'D');
    };

    window.printing.setFontSize = function (fontSizeSelector) {
      return window.printing.data.push(chr(29) + chr(33) + fontSizeSelector);
    };

    window.printing.activateCenter = function () {
      return window.printing.data.push(chr(27) + 'a' + '1');
    };

    window.printing.deactivateCenter = function () {
      return window.printing.data.push(chr(27) + 'a' + '0');
    };

    window.printing.activateLineGraphicCharacterSet = function () {
      return window.printing.data.push(chr(27) + 't' + chr(27) + chr(7));
    };

    window.printing.activateLineStyle = function () {
      window.printing.data.push(chr(15));
      window.printing.data.push(chr(27) + chr(80));
      return window.printing.activateLineGraphicCharacterSet();
    };

    window.printing.setStyle = function (style) {
      printing.initializePrinter();
      switch (style) {
        case 'header':
          return printing.activateHeadingFont();
        case 'center':
          return printing.activateCenter();
        case 'standard':
          return printing.activateStandardStyle();
        default:
          return printing.initializePrinter();
      }
    };

    chr = function (asciiCode) {
      return String.fromCharCode(asciiCode);
    };

    window.printing.printDocument = function (documentLinesArray) {
      var chars,
        currentStyle,
        i,
        lastStyle,
        len,
        printer,
        printerLine,
        data,
        config;
      chars = printing.chars;
      data = window.printing.data;
      printing.initializePrinter();
      lastStyle = '';
      for (i = 0, len = documentLinesArray.length; i < len; i++) {
        printerLine = documentLinesArray[i];
        currentStyle = printerLine.style != null
          ? printerLine.style
          : '';
        lastStyle = currentStyle;
        data.push(printerLine.text + chars.RETURN);
      }
      data.push('\r\n');
      data.push('\r\n');
      data.push('\r\n');
      data.push('\r\n');
      data.push('\r\n');
      data.push('\r\n');
      printing.initializePrinter();
      printing.cutPaper();
    };

    window.printing.chars = {
      TAB: chr(9),
      RETURN: '\r\n',
      FORMFEED: chr(12),
      LINEFEED: chr(10),
      REVERSEFEED: chr(27) + chr(74),
      NEWLINE: '\n',
      CR: '\r',
      INIT: chr(27) + chr(64),
      BOLD: chr(27) + chr(8),
      REGULAR: chr(27) + chr(70),
      CONDENSED: chr(27) + chr(1),
      NOCONDENSED: chr(18),
      BIGFONT: chr(27) + 'w1',
      NOBIGFONT: chr(27) + 'w0',
      VSPACING6LPI: chr(27) + chr(50),
      VSPACING8LPI: chr(27) + chr(48),
      DRAWLINE: chr(196),
      DRAWDLINE: chr(205),
      TOPLEFT: chr(218),
      TOPRIGHT: chr(191),
      VLINE: chr(33),
      VINTERSECTR: chr(180),
      VINTERSECTL: chr(195),
      VINTERSECTTC: chr(194),
      INTERSECT: chr(197),
      VINTERSECTBC: chr(193),
      BOTTOMLEFT: chr(192),
      BOTTOMRIGHT: chr(217),
      HALF_FRACTION: chr(0x00BD)
    };

    window.padRight = function (value, size, paddingChar) {
      var s;
      s = value + '';
      paddingChar || (paddingChar = ' ');
      while (s.length < size) {
        s = s + paddingChar;
      }
      return s;
    };

    window.padLeft = function (value, size, paddingChar) {
      var s;
      s = value + '';
      paddingChar || (paddingChar = ' ');
      while (s.length < size) {
        s = paddingChar + s;
      }
      return s;
    };
    extractWordBlocksFromDocumentLine = function (line) {
      var blocks,
        character,
        currentWord,
        fn,
        i,
        index,
        lastCharacterWasSpace,
        len,
        ref;
      blocks = [];
      currentWord = '';
      lastCharacterWasSpace = false;
      ref = line['text'];
      fn = function (character) {
        if (character === ' ') {
          if (lastCharacterWasSpace) {
            currentWord = currentWord + character;
          } else {
            blocks.push(currentWord);
            currentWord = character;
          }
          lastCharacterWasSpace = true;
        } else {
          if (lastCharacterWasSpace) {
            blocks.push(currentWord);
            currentWord = character;
          } else {
            currentWord = currentWord + character;
          }
          lastCharacterWasSpace = false;
        }
        if (index === line['text'].length - 1) {
          return blocks.push(currentWord);
        }
      };
      for (index = i = 0, len = ref.length; i < len; index = ++i) {
        character = ref[index];
        fn(character);
      }
      return blocks;
    };
    window.printing.printUsingGenericMethod = function (lines) {
      var chars,
        i,
        len,
        line,
        printer,
        data,
        config;
      chars = window.printing.chars;
      data = window.printing.data;
      printing.initializePrinter();
      for (i = 0, len = lines.length; i < len; i++) {
        line = lines[i];
        if (!line['image'] && !line['barcode']) {
          (function (line) {
            var blocks,
              fn,
              j,
              lastWordWasBold,
              len1,
              word;
            line['text'] = line['text'].replace(new RegExp('\u00BD', 'g'), String.fromCharCode(171));
            if ((line['smallFont'] || line['smallFontGeneric']) && !line['isHeaderJava']) {
              printing.activateStandardStyle();
            } else if (line['headerFont'] || line['isHeaderJava']) {
              printing.activateHeadingFont();
            } else {
              printing.deactivateFontStyle();
            }
            if (line['center']) {
              printing.activateCenter();
            }
            if (line['bold'] && line['extra_data'] !== 'alternateBoldWords') {
              printing.activateBold();
            }
            if (line['extra_data'] === 'alternateBoldWords') {
              blocks = extractWordBlocksFromDocumentLine(line);
              lastWordWasBold = false;
              fn = function (word) {
                if (word.trim().length === 0) {
                  window.printing.deactivateBold();
                  return data.push(word);
                } else {
                  if (lastWordWasBold) {
                    window.printing.deactivateBold();
                    data.push(word);
                  } else {
                    window.printing.activateBold();
                    data.push(word);
                  }
                  return lastWordWasBold = !lastWordWasBold;
                }
              };
              for (j = 0, len1 = blocks.length; j < len1; j++) {
                word = blocks[j];
                fn(word);
              }
              data.push(chars.RETURN);
            } else {
              data.push(line['text'] + chars.RETURN);
            }
            if (line['extra_data'] === 'extraCarriageReturnAppletMethodOnly') {
              data.push(chars.RETURN);
            }
            printing.deactivateBold();
            return printing.deactivateCenter();
          })(line);
        }
      }
      data.push(chars.RETURN);
      data.push(chars.RETURN);
      data.push(chars.RETURN);
      data.push(chars.RETURN);
      printing.cutPaper();
      if (ws.readyState == ws.OPEN) {
        ws.send(JSON.stringify(data));
        window.printing.data = [];
      }
    };

    window.printing.fillContainerWithDocumentLines = function (lines, $container) {
      var fn,
        i,
        len,
        line;
      $container.empty();
      fn = function (line) {
        var barcode,
          blocks,
          fn1,
          j,
          lastWordWasBold,
          len1,
          printedLine,
          settings,
          word;
        if (line['image']) {
          printedLine = $('<div class=\'printed-ticket-logo\'><img src=\'' + line['extra_data'] + '\' /></div>');
          return $container.append(printedLine);
        } else if (line['barcode']) {
          barcode = line['extra_data'];
          settings = {
            barWidth: 1.5,
            barHeight: 50
          };
          printedLine = $('<div class=\'printed-ticket-barcode\'></div>');
          $container.append(printedLine);
          return printedLine.barcode(barcode, 'code93', settings);
        } else {
          printedLine = $('<div class=\'printed-line\'>' + line['text'] + '</div>');
          if (line['smallFont']) {
            printedLine.addClass('printing-small-font');
          }
          if (line['headerFont']) {
            printedLine.addClass('printing-header-font');
          }
          if (line['bold'] && line['extra_data'] !== 'alternateBoldWords') {
            printedLine.addClass('printing-bold');
          }
          if (line['extra_data'] === 'alternateBoldWords') {
            printedLine.addClass('printing-bold');
            printedLine.empty();
            blocks = extractWordBlocksFromDocumentLine(line);
            lastWordWasBold = false;
            fn1 = function (word) {
              if (word.trim().length === 0) {
                return printedLine.append(word);
              } else {
                if (lastWordWasBold) {
                  printedLine.append($('<span class=\'printing-non-bold\'>' + word + '</span>'));
                } else {
                  printedLine.append(word);
                }
                return lastWordWasBold = !lastWordWasBold;
              }
            };
            for (j = 0, len1 = blocks.length; j < len1; j++) {
              word = blocks[j];
              fn1(word);
            }
          }
          if (line['center']) {
            printedLine.addClass('printing-center');
          }
          if (line['italic']) {
            printedLine.addClass('printing-italic');
          }
          if (line['extra_data'] === 'useClientFooterFont') {
            printedLine.addClass('client-footer-font');
          }
          if (line['extra_data'] === 'alternativeFont') {
            printedLine.addClass('alternative-footer-font');
          }
          return $container.append(printedLine);
        }
      };
      for (i = 0, len = lines.length; i < len; i++) {
        line = lines[i];
        fn(line);
      }
      var data = $container.html();
      $container.empty();
      return data;
    };

    window.printing.printUsingBrowserMethod = function (lines) {
      var fn,
        i,
        len,
        line,
        printContainer;
      printContainer = $('#print-section');
      printContainer.empty();
      fn = function (line) {
        var barcode,
          blocks,
          fn1,
          j,
          lastWordWasBold,
          len1,
          printedLine,
          settings,
          word;
        if (line['image']) {
          printedLine = $('<div class=\'printed-ticket-logo\'><img src=\'' + line['extra_data'] + '\' /></div>');
          return printContainer.append(printedLine);
        } else if (line['barcode']) {
          barcode = line['extra_data'];
          settings = {
            barWidth: 1.5,
            barHeight: 50
          };
          printedLine = $('<div class=\'printed-ticket-barcode\'></div>');
          printContainer.append(printedLine);
          return printedLine.barcode(barcode, 'code93', settings);
        } else {
          printedLine = $('<div class=\'printed-line\'>' + line['text'] + '</div>');
          if (line['smallFont']) {
            printedLine.addClass('printing-small-font');
          }
          if (line['extraSmallFont']) {
            printedLine.addClass('printing-extra-small-font');
          }
          if (line['headerFont'] || line['headerFontDriver']) {
            printedLine.addClass('printing-header-font');
          }
          if (line['bold'] && line['extra_data'] !== 'alternateBoldWords') {
            printedLine.addClass('printing-bold');
          }
          if (line['extra_data'] === 'alternateBoldWords') {
            printedLine.addClass('printing-bold');
            printedLine.empty();
            blocks = extractWordBlocksFromDocumentLine(line);
            lastWordWasBold = false;
            fn1 = function (word) {
              if (word.trim().length === 0) {
                return printedLine.append(word);
              } else {
                if (lastWordWasBold) {
                  printedLine.append($('<span class=\'printing-non-bold\'>' + word + '</span>'));
                } else {
                  printedLine.append(word);
                }
                return lastWordWasBold = !lastWordWasBold;
              }
            };
            for (j = 0, len1 = blocks.length; j < len1; j++) {
              word = blocks[j];
              fn1(word);
            }
          }
          if (line['center']) {
            printedLine.addClass('printing-center');
          }
          if (line['italic']) {
            printedLine.addClass('printing-italic');
          }
          if (line['extra_data'] === 'useClientFooterFont') {
            printedLine.addClass('client-footer-font');
          }
          if (line['extra_data'] === 'alternativeFont') {
            printedLine.addClass('alternative-footer-font');
          }
          return printContainer.append(printedLine);
        }
      };
      for (i = 0, len = lines.length; i < len; i++) {
        line = lines[i];
        fn(line);
      }
      window.print();
      printContainer.empty();
      return void 0;
    };
  },
  printDocumentLines({ printModeId, lines }) {
    if (process.env.NODE_ENV === 'none') {
      return null;
    } else if (printModeId === PrintMode.generic) {
      return window.printing.printUsingGenericMethod(lines);
    } else if (printModeId === PrintMode.driver) {
      return window.printing.printUsingBrowserMethod(lines);
    }
  }
};
