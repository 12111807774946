<template>
  <div class="d-flex playing-area">
    <digits-input
      ref="digits"
      :digits="digits"
      class="mr-3"
      @update:digits="onUpdateDigits"
      @division-bet-toggle="$emit('division-bet-toggle')"
      @close-ticket="$emit('close-ticket')"
      @pay-ticket="$emit('pay-ticket')"
      @duplicate-ticket="$emit('open-duplicate-modal')"
      @clear-play-data="clearPlayData" />
    <amount-input
      ref="amount"
      :amount="amount"
      @update:amount="onUpdateAmount"
      @division-bet-toggle="$emit('division-bet-toggle')"
      @focus="onAmountFocus"
      @clear-play-data="clearPlayData"
      @error="$emit('error', $event)" />
  </div>
</template>

<script>
import DigitsInput from "@/components/tickets/DigitsInput.vue";
import AmountInput from "@/components/tickets/AmountInput.vue";
import { isPlayValid } from "@/utils/roulette/validation.js";
import { parse as parsePlays } from "@/utils/roulette/parse.js";

export default {
  name: "PlayingArea",
  components: {
    DigitsInput,
    AmountInput,
  },
  props: {
    digits: {
      type: Object,
      required: true,
    },
    amount: {
      type: Object,
      required: true,
    },
    configuration: {
      type: Object,
      required: true,
    },
    round: {
      type: Object,
      required: true,
    },
  },
  methods: {
    focusDigits() {
      this.$refs.digits.focus();
    },
    focusAmount() {
      this.$refs.amount.focus();
    },
    onAmountFocus(e) {
      const digits = this.digits.value;

      if (digits === null || !isPlayValid(digits)) {
        this.$refs.digits.focus();

        this.$emit("error", [this.$t("validations.others.tickets.invalid_play")]);
      }
    },
    onUpdateDigits(payload) {
      const { focusAmount } = payload.options;

      if (payload.valid) {
        this.$emit("update:digits", payload.digits);
        if (focusAmount) {
          this.$refs.amount.focus();
        }
      } else {
        const invalidPlayError = this.$t("validations.others.tickets.invalid_play");

        this.$emit("update:digits", null);
        if (payload.digits && payload.digits.length > 0) {
          this.$emit("error", [invalidPlayError]);
        }
      }
    },
    onUpdateAmount(payload) {
      this.$emit("update:amount", payload.amount);

      const { createPlay } = payload.options,
        digits = this.digits.value,
        amount = payload.amount,
        configuration = this.configuration.value;

      if (createPlay) {
        const errors = [];

        if (digits === null || !isPlayValid(digits)) {
          errors.push(this.$t("validations.others.tickets.invalid_play"));
        }
        if (amount === null) {
          errors.push(this.$t("validations.others.tickets.invalid_amount"));
        } else {
          if (configuration.maximumAmountPerPlay && amount > configuration.maximumAmountPerPlay) {
            errors.push(this.$t("validations.others.tickets.amount_exceeds_max_amount_per_play"));
          }
        }
        if (this.round.value.noMoreBets) {
          errors.push(this.$t("validations.others.tickets.current_round_no_more_bets"));
        }

        if (errors.length > 0) {
          this.$emit("error", errors);
        } else {
          this.$emit("play-parsing-in-progress");
          this.$emit("update:digits", null);
          this.$refs.digits.focus();

          setTimeout(() => {
            const generatedPlays = parsePlays(digits, amount);

            this.$emit("plays-parsed", {
              plays: generatedPlays,
              creationMethod: digits,
            });
            this.$emit("clear-errors");
          }, 0);
        }
      }
    },
    clearPlayData(payload) {
      this.$emit("update:digits", null);
      this.$emit("update:amount", null);
      if (payload.focusDigits) {
        this.$nextTick(() => this.$refs.digits.focus());
      }
    },
  },
};
</script>

<style scoped lang="scss">
.playing-area {
  height: 56px;
}

input {
  font-size: 50px;
  line-height: 56px;
  background-color: #16471f;
  border: 3px solid white;
  border-radius: 5px;
  color: white;
  width: 100%;
  height: 100%;
  padding: 4px;
}
</style>
