import { TICK_CLOCK, SET_TIME, SET_TICK, SET_TIME_ZONE } from "./mutations.js";
import { START_CLOCK, STOP_CLOCK } from "./actions.js";

const MODULE_NAME = "clock";

export {
  MODULE_NAME,
};

export default {
  namespaced: true,
  state: {
    timeZone: null,
    time: null,
    tick: null,
  },
  actions: {
    [START_CLOCK]({ commit }, time) {
      commit(SET_TIME, time);

      const tick = setInterval(() => {
        commit(TICK_CLOCK);
      }, 1000);

      commit(SET_TICK, tick);
    },
    [STOP_CLOCK]({
      commit,
      state,
    }) {
      clearInterval(state.tick);
      commit(SET_TICK, null);
      commit(SET_TIME, null);
    },
  },
  mutations: {
    [TICK_CLOCK](state) {
      state.time = state.time.clone().
        add(1, "seconds");
    },
    [SET_TIME](state, time) {
      state.time = time;
    },
    [SET_TICK](state, tick) {
      state.tick = tick;
    },
    [SET_TIME_ZONE](state, timezone) {
      state.timeZone = timezone;
    },
  },
};
