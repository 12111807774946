<template>
  <v-dialog
    ref="dialog"
    :value="show"
    width="600"
    @click:outside="closeDialog">
    <v-card>
      <v-card-title class="headline grey lighten-2">
        {{ $t("views.tickets.create.link_screens") }}
      </v-card-title>
      <v-container>
        <v-row>
          <v-col cols="12">
            <validation-observer
              ref="validationObserver">
              <validation-provider
                v-slot="{ errors }"
                :rules="validations.code"
                name="code"
                slim>
                <v-text-field
                  :value="code"
                  :label="$t('attributes.rouletteLoginCodes.code')"
                  :error-messages="errors"
                  name="code"
                  type="text"
                  maxlength="6"
                  @input="onInput" />
              </validation-provider>
            </validation-observer>
          </v-col>
        </v-row>
      </v-container>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="secondary"
          text
          @click="closeDialog">
          {{ $t("common.close") }}
        </v-btn>
        <v-btn
          color="primary"
          text
          @click="connectScreen">
          {{ $t("common.connect") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ContentLoader from "../../mixins/ContentLoader.js";
import Validatable from "@/mixins/Validatable.js";
import { createSession } from "@/api/session.js";
import SessionCreateCategory from "@/imported/SessionCreateCategory.json";
import { ValidationObserver } from "vee-validate";

export default {
  name: "LinkScreenDialog",
  components: {
    ValidationObserver,
  },
  mixins: [ContentLoader, Validatable],
  props: {
    show: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      code: null,
      validations: {
        code: "required",
      },
    };
  },
  methods: {
    connectScreen() {
      const { validationObserver } = this.$refs;

      validationObserver.validate().
        then(isValid => {
          if (isValid) {
            const sessionInfo = {
              session: {
                code: this.code,
              },
              category: SessionCreateCategory.codeRoulette,
            };
            createSession(sessionInfo).
              then(this.closeDialog).
              catch(e => {
                this.handleErrorResponse(this.$refs["validationObserver"], e);
              });
          }
        });
    },
    closeDialog() {
      this.code = null;
      this.$refs.validationObserver.reset();
      this.$emit("dismiss");
    },
    onInput(inputValue) {
      this.code = inputValue ?
        inputValue.toString().
          toUpperCase() :
        inputValue;
    },
  },
};
</script>

<style
  lang="scss"
  scoped>
#sale-summary-dialog .v-card.v-sheet {
  background-color: transparent;
  height: 100%;
}
</style>
