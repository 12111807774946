import httpClient from "./httpClient";
import TicketParamsCategory from "@/imported/TicketParamsCategory.json";
import TicketIndexCategory from "@/imported/TicketIndexCategory.json";
import TicketShowCategory from "@/imported/TicketShowCategory.json";

const resource = "tickets";

export function create(ticket) {
  return httpClient.post(`/${resource}`, { ticket });
}

export function getCreateParams() {
  return httpClient.get(`/${resource}/params`, { params: { category: TicketParamsCategory.create } });
}

export function getPoolIndexParams() {
  return httpClient.get(`/${resource}/params`, { params: { category: TicketParamsCategory.poolIndex } });
}

export function getAdminIndexParams() {
  return httpClient.get(`/${resource}/params`, { params: { category: TicketParamsCategory.generalIndex } });
}

export function getPoolTickets(form) {
  return httpClient.get(`/${resource}`, {
    params: {
      ...form,
      category: TicketIndexCategory.bettingPool,
    },
  });
}

export function getAdminTickets(form) {
  return httpClient.get(`/${resource}`, {
    params: {
      ...form,
      category: TicketIndexCategory.general,
    },
  });
}

export function extendRound(roundId) {
  return httpClient.put(`/${resource}/extend`, { roundId: roundId });
}

export function fetchTicketById(ticketId) {
  return httpClient.get(`/${resource}/show`, {
    params: {
      id: ticketId,
      category: TicketShowCategory.byId,
    },
  });
}

export function fetchTicketByCode(code) {
  return httpClient.get(`/${resource}/show`, {
    params: {
      code: code,
      category: TicketShowCategory.byCode,
    },
  });
}

export function cancelTicket(ticketId) {
  return httpClient.put(`/${resource}/${ticketId}/cancel`);
}

export function duplicateTicket(sequenceNumber) {
  return httpClient.get(`/${resource}/duplicate`, { params: {  sequenceNumber  } });
}

export function payTicket(ticketId) {
  return httpClient.put(`/${resource}/${ticketId}`);
}

export default {
  create,
  getCreateParams,
  getAdminIndexParams,
  getAdminTickets,
  cancelTicket,
  duplicateTicket
};
