<template>
  <td
    :id="`cell-${text}`"
    :class="`background-${color} cell`"
    :colspan="colspan"
    :rowspan="rowspan"
    @click="$emit('selected-play', {play: value.play})">
    <div
      v-if="isColorField(text)"
      :class="{'no-rotate': noRotate}"
      class="center-diamond">
      <div
        :class="`colored-diamond ${text}-colorant`" />
    </div>
    <div
      v-else
      :class="{'no-rotate': noRotate,'d-inline': spanDInLine, 'number': type==='number'}">
      {{ text }}
    </div>
  </td>
</template>

<script>
export default {
  name: "Cell",
  props: {
    value: {
      type: Object,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      required: true,
      validator: function(value) {
        return ["red", "black", "green", "none"].indexOf(value) !== -1;
      },
    },
    colspan: {
      type: String,
      required: false,
      default: "1",
    },
    rowspan: {
      type: String,
      required: false,
      default: "1",
    },
    noRotate: {
      type: Boolean,
      required: false,
      default: false,
    },
    spanDInLine: {
      type: Boolean,
      required: false,
      default: false,
    },
    type: {
      type: String,
      required: false,
      default: "",
    },
  },
  methods: {
    isColorField(text) {
      return ["red", "black"].indexOf(text) !== -1;
    },
  },
};
</script>

<style
  lang="scss"
  scoped>

td:hover {
  border-color: red;
  border-style: dashed;
}

td {
  border: 2px solid white;
  text-align: center;
  padding: 8px;
  white-space: nowrap;
  cursor: pointer;

  & > div {
    color: white;
    font-size: 1.4em;
    font-weight: bolder;

    &.number {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1.4em;
      height: 33px;
      width: 33px;
      border-radius: 100%;
      margin-left: auto;
      margin-right: auto;
    }

    & > div.colored-diamond {
      border: 3px solid white;
      margin-top: 5px;
      height: 25px;
      width: 25px;
      display: inline-block;
      transform: rotate(45deg);

      &.red-colorant {
        background-color: red;
      }

      &.black-colorant {
        background-color: black;
      }
    }
  }

  &.background-green {
    border-width: 4px;
    border-top-left-radius: 100%;
    border-bottom-left-radius: 100%;
  }

  &.background-red {
    div {
      background: #ca1a18;
    }
  }

  &.background-black {
    div {
      background: black;
    }
  }
}
</style>
