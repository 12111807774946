import { parseInt } from "./utils";
import * as Keys from "./keys";

/**
 *
 * @param {String} string The string to analyze.
 * @return {Boolean} A boolean indicating if all elements of string are the same.
 *
 * @example
 *
 *     areAllCharactersTheSame("123") => false
 *     areAllCharactersTheSame("111") => true
 */
export function areAllCharactersTheSame(string) {
  return /^(.)\1+$/.test(string);
}

/**
 *
 * @param {String} string The string to analyze.
 * @return {Boolean} A boolean indicating if all elements of string are numbers.
 *
 * @example
 *
 *     areAllCharactersNumbers("12a") => false
 *     areAllCharactersNumbers("122") => true
 *     areAllCharactersNumbers("1.2") => false
 */
export function areAllCharactersNumbers(string) {
  return /^\d+$/.test(string);
}

/**
 *
 * @param {KeyboardEvent} event - The event to analyze.
 * @return {boolean} A boolean indicating if the pressed key is a number.
 *
 * @example
 *
 *     isNumericKeyPressed(event)
 */
export function isNumericKeyPressed(event) {
  return [
    Keys.NUMBER_0,
    Keys.NUMBER_1,
    Keys.NUMBER_2,
    Keys.NUMBER_3,
    Keys.NUMBER_4,
    Keys.NUMBER_5,
    Keys.NUMBER_6,
    Keys.NUMBER_7,
    Keys.NUMBER_8,
    Keys.NUMBER_9,
  ].includes(event.key);
}

/**
 *
 * @param {KeyboardEvent} event - The event to analyze.
 * @param {String} targetKey - The key constant to test against.
 * @return {Boolean} A boolean indicating if the pressed key is the targetKey.
 *
 * @example
 *     import * as Keys from 'Keys.js';
 *
 *     isKey(event, Keys.LETTER_L)
 */
export function isKey(event, targetKey) {
  return event.key.toLowerCase() === targetKey.toLowerCase();
}

/**
 *
 * @param {KeyboardEvent} event - The event to analyze.
 * @param {...String} targetKeys - The keys var arg collection to test against.
 * @return {Boolean} A boolean indicating if the pressed key is one from the collection.
 *
 * @example
 *     import * as Keys from 'Keys.js';
 *
 *     isKeyFromSet(event, Keys.LETTER_L, Keys.LETTER_M)
 */
export function isKeyFromSet(event, ...targetKeys) {
  return targetKeys.some(key => isKey(event, key));
}

/**
 * This function will indicate if the current playText
 * is consider as completed and the amount input should
 * be focused automatically.
 *
 * @param {String} playText - The play text to analyze.
 * @return {boolean} A boolean indicating if the play input is completed and should focus amount input automatically.
 *
 * @example
 *
 *     isPlayComplete('12+') => false
 *     isPlayComplete('12-') => false
 *     isPlayComplete('1') => false
 *     isPlayComplete('+-') => true
 */
export function isPlayComplete(playText) {
  const playTextLoweredCase = playText.toLowerCase(),
    { length } = playTextLoweredCase,
    firstChar = playTextLoweredCase.charAt(0),
    lastChar = playTextLoweredCase.charAt(length - 1);

  if (length === 2) {
    if (firstChar === ".") {
      return true;
    } else if (lastChar === "-") {
      return true;
    } else if (areAllCharactersNumbers(playTextLoweredCase) && !playTextLoweredCase.includes("12")) {
      return true;
    } else {
      return areAllCharactersTheSame(playTextLoweredCase);
    }
  } else if (length === 4) {
    return true;
  }

  return false;
}

/**
 * @param {string} inputValue - The play text to analyze.
 * @param {KeyboardEvent} event - The keyboard event of the pressed key.
 * @return {boolean} A boolean indicating if the pressed key is valid.
 *
 * @example
 *
 *     isKeyValidForPlay('.', event)
 *     isKeyValidForPlay('-', event)
 */
export function isKeyValidForPlay(inputValue, event) {
  const playInputLoweredCaseText = inputValue.toLowerCase(),
    playInputLength = playInputLoweredCaseText.length,
    isPressedKeyNumeric = isNumericKeyPressed(event),
    firstChar = playInputLoweredCaseText.charAt(0),
    number = parseInt(event.key),
    selectionLength = event.target ?
      event.target.selectionEnd - event.target.selectionStart :
      0;

  if (isKeyFromSet(event, Keys.BACKSPACE, Keys.SLASH, Keys.TAB, Keys.DELETE, Keys.ARROW_UP, Keys.LETTER_C, Keys.LETTER_P, Keys.LETTER_L)) {
    return true;
  }

  if (playInputLength === 0 || selectionLength === playInputLength) {
    const closeTicket = isKey(event, Keys.ASTERISK) && playInputLength === 0,
      allowAllPlayInitialCharacter = isKeyFromSet(event, Keys.NUMBER_0, Keys.NUMBER_1, Keys.NUMBER_2, Keys.NUMBER_3, Keys.DOT, Keys.PLUS, Keys.MINUS);

    return closeTicket || allowAllPlayInitialCharacter;
  } else if (playInputLength === 1) {
    if (areAllCharactersNumbers(playInputLoweredCaseText)) {
      if (firstChar === "3") {
        return isPressedKeyNumeric && number >= 0 && number <= 6;
      } else {
        const allowHalfNumbersPlay = isKey(event, Keys.MINUS),
          allowDirectPlay = isPressedKeyNumeric;

        return allowDirectPlay || allowHalfNumbersPlay;
      }
    } else {
      if (firstChar === ".") {
        return isKeyFromSet(event, Keys.NUMBER_1, Keys.NUMBER_2, Keys.NUMBER_3);
      } else if (firstChar === "-") {
        return isKey(event, Keys.MINUS);
      } else if (firstChar === "+") {
        return isKey(event, Keys.PLUS);
      } else {
        return isPressedKeyNumeric || isKeyFromSet(event, Keys.PLUS, Keys.MINUS);
      }
    }
  } else if (playInputLength === 2) {
    const isDozenPlay = playInputLoweredCaseText.includes("12") && isKey(event, Keys.PLUS);

    return isDozenPlay;
  } else if (playInputLength === 3) {
    const lastChar = playInputLoweredCaseText.charAt(playInputLoweredCaseText.length - 1),
      isDozenPlay = playInputLoweredCaseText.includes("12") && lastChar === "+";

    if (isDozenPlay) {
      return isKeyFromSet(event, Keys.NUMBER_1, Keys.NUMBER_2, Keys.NUMBER_3);
    }
  }

  return false;
}

// eslint-disable-next-line
export function isKeyValidForAmount(amountInputText, event, allowDecimals) {
  const char = event.key.toLowerCase(),
    isNumericChar = isNumericKeyPressed(event),
    value = amountInputText?.toString() || "",
    { length } = value,
    isEnterPressed = isKey(event, Keys.ENTER),
    currentAmountBlock = value,
    selectionLength = event.target ?
      event.target.selectionEnd - event.target.selectionStart :
      0;

  if (
    isKey(event, Keys.BACKSPACE) ||
    isKey(event, Keys.TAB) ||
    isKey(event, Keys.DELETE) ||
    isKey(event, Keys.ARROW_UP) ||
    isKey(event, Keys.SLASH)
  ) {
    return true;
  }

  if (length === 0 || selectionLength === length) {
    return isNumericChar || (allowDecimals && char === Keys.DOT);
  }
  if (currentAmountBlock.includes(Keys.DOT)) {
    const [, decimalSpace] = currentAmountBlock.split(Keys.DOT),
      decimalLength = decimalSpace.length || 0;

    if (decimalLength === 0) {
      if (isNumericChar) {
        return true;
      }
    } else if (decimalLength < 2) {
      if (isNumericChar || isEnterPressed) {
        return true;
      }
    } else if (decimalLength === 2) {
      if (isEnterPressed) {
        return true;
      }
    }
  } else if (length >= 1) {
    if (isNumericChar || (allowDecimals && char === Keys.DOT) || isEnterPressed) {
      return true;
    }
  }

  return false;
}

export function isPlayValid(playInputText) {
  const playInputLoweredCaseText = playInputText.toLowerCase(),
    REGEX = /[qd+\-.]/gu;

  if (playInputLoweredCaseText === "+" || playInputLoweredCaseText === "-") {
    return true;
  }

  let sections = null,
    rawInputNumber = null,
    rawNumbersLength = null,
    appendix = null,
    appendixLength = null;

  if (playInputLoweredCaseText.includes(Keys.PLUS)) {
    sections = playInputLoweredCaseText.split(Keys.PLUS);
  } else {
    sections = [playInputLoweredCaseText];
  }
  rawInputNumber = sections[0].replace(REGEX, "");
  rawNumbersLength = rawInputNumber.length;
  appendix = sections[1] || "";
  appendixLength = appendix.length;

  // Handle directos without preceding 0's
  if (playInputLoweredCaseText.length === 0 || playInputLoweredCaseText.length === 1) {
    return false;
  }

  return true;
}
