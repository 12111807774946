<template>
  <input
    id="amount-input"
    ref="amount"
    v-model="value"
    type="tel"
    autocomplete="off"
    :readonly="readonly"
    @click="$emit('click', $event)"
    @keydown="onKeyDown"
    @blur="onBlur"
    @focus="onFocus"
  />
</template>

<script>
import { isKeyValidForAmount, isKey } from "@/utils/roulette/validation.js";
import * as Keys from "../../utils/roulette/keys";

function isAmountValid(amount) {
  const value = (amount || "").toString();

  return parseFloat(value) > 0;
}

export default {
  name: "AmountInput",
  props: {
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
    amount: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      value: this.amount.value,
    };
  },
  watch: {
    "amount.value"(value) {
      this.value = value;
    },
  },
  methods: {
    select() {
      this.$refs.amount.select();
    },
    focus() {
      this.$refs.amount.focus();
    },
    parseAmountAndNotify(value, createPlay) {
      if (isAmountValid(value)) {
        this.$emit("update:amount", {
          amount: parseFloat(value),
          options: { createPlay },
        });
      } else {
        if (createPlay) {
          const invalidAmountMessage = this.$t("validations.others.tickets.invalid_amount");

          this.$emit("error", [invalidAmountMessage]);
        }
        this.$emit("update:amount", {
          amount: null,
          options: { createPlay: false },
        });
      }
    },
    onBlur(event) {
      this.parseAmountAndNotify(event.target.value, false);
    },
    onFocus(event) {
      event.target.select();
      this.$emit("focus", event);
    },
    onKeyDown(event) {
      const isEnterPressed = isKey(event, Keys.ENTER),
        currentValue = this.value;

      if (isEnterPressed) {
        this.$emit("clear-errors");
        this.parseAmountAndNotify(currentValue, true);

        return;
      }

      if (isKey(event, Keys.ARROW_UP)) {
        event.preventDefault();
        this.value = "";
        this.$emit("clear-play-data", { focusDigits: true });

        return;
      }

      if (isKey(event, Keys.SLASH)) {
        event.preventDefault();
        this.$emit("division-bet-toggle");

        return;
      }

      if (isKey(event, Keys.LETTER_L)) {
        event.preventDefault();
        this.$emit("clear-ticket");

        return;
      }

      if (!isKeyValidForAmount(currentValue, event, false)) {
        event.preventDefault();
      }
    },
  },
};
</script>
