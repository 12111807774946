<template>
  <span>{{ currentTime }}</span>
</template>

<script>

import { createNamespacedHelpers } from "vuex";
import { MODULE_NAME as CLOCK_MODULE_NAME } from "../store/modules/clock";

const { mapState: mapStateForClock } = createNamespacedHelpers(CLOCK_MODULE_NAME);

export default {
  name: "ClockDisplay",
  props: {
    showDate: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapStateForClock({
      currentTime({ time }) {
        if (time) {
          if (this.showDate) {
            return `${time.format("hh:mm:ss A")} | ${time.format("DD/MM/YYYY")}`;
          }

          return time.format("hh:mm:ss A");
        }

        return "";
      },
    }),
  },

};
</script>
<style scoped>
span {
  font-size: 1em;
}
</style>

