<template>
  <v-dialog
    :value="show"
    width="500"
    @click:outside="$emit('dismiss')">
    <v-card>
      <v-card-title>
        {{ $t('views.tickets.create.help') }}
        <v-spacer />
        <v-btn
          icon
          @click="$emit('dismiss')">
          <font-awesome-icon icon="times" />
        </v-btn>
      </v-card-title>
      <v-card-text>
        <ul>
          <li
            v-for="(value, key) in entries"
            :key="key">
            <h5>{{ key }}</h5>
            <ul>
              <li
                v-for="(rule,index) in value"
                :key="index">
                {{ rule }}
              </li>
            </ul>
          </li>
        </ul>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { fetchHelp } from "@/api/games.js";
import Game from "../../../imported/Game.json";

export default {
  name: "HelpDialog",
  props: {
    show: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      entries: [],
    };
  },
  watch: {
    show(value) {
      if (value) {
        this.fetchHelp();
      }
    },
  },
  methods: {
    fetchHelp() {
      fetchHelp(Game.roulette).
        then(response => {
          this.entries = response.data.help;
        });
    },
  },
};
</script>

<style scoped>

</style>
