<template>
  <v-simple-table
    dense
    fixed-header
    height="200px">
    <template #default>
      <thead>
        <tr>
          <th class="text-left">
            #
          </th>
          <th class="text-left">
            $
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="item in tickets.value"
          :key="item.id">
          <td>{{ item.sequence }}</td>
          <td>{{ item.amount }}</td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
export default {
  name: "LatestWinnerTickets",
  props: {
    tickets: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped>

</style>
