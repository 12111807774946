<template>
  <v-dialog
    :value="show"
    persistent
    max-width="350"
    @click:outside="$emit('update:show', false)">
    <v-card>
      <v-card-title class="headline">
        {{ title }}
      </v-card-title>
      <v-card-text>
        {{ message }}
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <slot
          name="cancel-button"
          :on-cancel="onCancel">
          <v-btn
            text
            @click="onCancel">
            <slot name="cancel-button-message">
              {{ cancelMessage }}
            </slot>
          </v-btn>
        </slot>
        <slot
          name="confirm-button"
          :on-confirm="onConfirm">
          <v-btn
            text
            @click="onConfirm">
            <slot name="confirm-button-message">
              {{ confirmMessage }}
            </slot>
          </v-btn>
        </slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ConfirmDialog",
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    message: {
      type: String,
      required: true,
    },
    confirmMessage: {
      type: String,
      required: false,
      default: null,
    },
    cancelMessage: {
      type: String,
      required: false,
      default: null,
    },
  },
  methods: {
    onConfirm() {
      this.$emit("confirm");
    },
    onCancel() {
      this.$emit("update:show", false);
      this.$emit("cancel");
    },
  },
};
</script>

<style scoped>

</style>
