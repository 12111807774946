<template>
  <v-btn
    color="green darken-3"
    height="100%"
    dark
    block
    :ripple="false"
    @click="$emit('click')">
    <font-awesome-icon
      icon="stopwatch"
      size="2x">
    </font-awesome-icon>
    <span
      v-if="round.value.extended > 0"
      id="extended-count">
      +{{ round.value.extended }}
    </span>
  </v-btn>
</template>

<script>
export default {
  name: "ExtendRoundButton",
  props: {
    round: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped>
#extended-count {
  font-size: 60%;
  position: absolute;
  right: 0;
  bottom: 0;
}
</style>
