<template>
  <input
    id="play-input"
    ref="digits"
    v-model="value"
    type="text"
    autocomplete="off"
    @focus="onFocus"
    @keydown="onKeyDown"
    @keyup="onKeyUp"
    @blur="onBlur">
</template>

<script>
import * as Keys from "../../utils/roulette/keys";
import { isPlayValid, isKeyValidForPlay, isPlayComplete, isKey } from "@/utils/roulette/validation.js";

export default {
  name: "PlayInput",
  props: {
    digits: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      value: this.digits.value,
    };
  },
  watch: {
    "digits.value"(value) {
      this.value = value;
    },
  },
  methods: {
    onFocus(event) {
      event.target.select();
      this.$emit("focus");
    },
    focus() {
      this.$refs.digits.focus();
    },
    onBlur(event) {
      const currentValue = event.target.value || "";

      this.$emit("update:digits", {
        valid: isPlayValid(currentValue),
        digits: currentValue,
        options: { focusAmount: false },
      });
    },
    onKeyDown(event) {
      const currentValue = event.target.value || "",
        isEnterPressed = isKey(event, Keys.ENTER),
      isCPressed = isKey(event, Keys.LETTER_C);

      if (isEnterPressed) {
        this.$emit("update:digits", {
          valid: isPlayValid(currentValue),
          digits: currentValue,
          options: { focusAmount: true },
        });

        return;
      }
      if (isCPressed) {
        this.$emit("duplicate-ticket")
      }

      if (isKeyValidForPlay(currentValue, event)) {
        if (isKey(event, Keys.ASTERISK) && currentValue?.length === 0) {
          event.preventDefault();
          this.$emit("close-ticket");

          return;
        }

        if (isKey(event, Keys.ARROW_UP)) {
          event.preventDefault();
          this.value = null;
          this.$emit("clear-play-data", { options: { focusDigits: true } });

          return;
        }

        if (isKey(event, Keys.SLASH)) {
          event.preventDefault();
          this.$emit("division-bet-toggle");

          return;
        }

        if (isKey(event, Keys.LETTER_L)) {
          event.preventDefault();
          this.$emit("clear-ticket");

          return;
        }

        if (isKey(event, Keys.LETTER_P)) {
          event.preventDefault();
          this.$emit("pay-ticket");

          return;
        }

        return;
      }
      event.preventDefault();
    },
    onKeyUp(event) {
      const currentValue = event.target.value || "",
        selectionLength = event.target.selectionEnd - event.target.selectionStart;

      if (currentValue?.length !== 0 && currentValue?.length !== selectionLength) {
        if (isPlayComplete(currentValue)) {
          this.$emit("update:digits", {
              valid: true,
              digits: currentValue,
              options: { focusAmount: true },
            },
          );
        }
      }
    },
  },
};
</script>

<style scoped>

</style>
