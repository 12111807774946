var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"transparent-background",attrs:{"id":"results"}},[_c('v-container',{attrs:{"background-color":"blue-grey"}},[_c('v-row',{staticClass:"mt-3"},[_c('v-col',{attrs:{"cols":"4"}},[_c('dialog-date-picker',{attrs:{"input-options":{
            'prepend-icon': 'mdi-calendar',
            'dark': true,
            'outlined': true,
            'backgroundColor': 'blue-grey darken-4',
            'label': _vm.$t('views.tickets.monitor.date')
          }},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-btn',{attrs:{"color":"blue-grey","background-color":"blue-grey darken-4","block":"","height":"4em","dark":""},on:{"click":_vm.fetchResults}},[_vm._v(" "+_vm._s(_vm.$t("views.tickets.monitor.search"))+" ")])],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-dialog',{attrs:{"max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-data-table',{staticClass:"elevation-1",attrs:{"id":"tickets-table","headers":_vm.headers,"items":_vm.results,"item-key":"name","hide-default-footer":"","disable-pagination":""},scopedSlots:_vm._u([{key:"item.prizes",fn:function(ref){
          var item = ref.item;
return [(item.prizes > 0)?_c('v-btn',_vm._g(_vm._b({attrs:{"plain":""},on:{"click":function($event){_vm.currentTickets = item.tickets}}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(item.prizes)+" ")]):_c('span',[_vm._v(_vm._s(item.prizes || '-'))])]}}],null,true)})]}}]),model:{value:(_vm.showTickets),callback:function ($$v) {_vm.showTickets=$$v},expression:"showTickets"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline text-center"},[_vm._v(" Tickets ")]),_c('v-card-text',[_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',[_vm._v(_vm._s(_vm.$t("views.roulette.results.tableColumns.ticket_number")))]),_c('th',[_vm._v(_vm._s(_vm.$t("views.roulette.results.tableColumns.prize")))]),_c('th',[_vm._v(_vm._s(_vm.$t("views.roulette.results.tableColumns.date")))])])]),_c('tbody',_vm._l((_vm.currentTickets),function(ticket){return _c('tr',{key:ticket.id},[_c('td',[_vm._v(_vm._s(ticket.number))]),_c('td',[_vm._v(_vm._s(ticket.processedPrizeToPay))]),_c('td',[_vm._v(_vm._s(ticket.createdAt))])])}),0)]},proxy:true}])})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.showTickets = false}}},[_vm._v(" "+_vm._s(_vm.$t("common.close"))+" ")])],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }