<template>
  <v-dialog
    :value="show"
    width="600"
    @click:outside="closeDialog">
    <v-card>
      <v-card-title class="headline grey lighten-2">
        {{ $t("views.reports.sales.title") }}
      </v-card-title>
      <v-card-text>
        <v-tabs v-model="tab">
          <v-tab>{{ $t("views.sales.daily.title") }}</v-tab>
          <v-tab>{{ $t("views.sales.weekly.title") }}</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <!-- Daily sales -->
          <v-tab-item>
            <v-container
              id="sale-summary-dialog"
              class="text-center">
              <!-- Filter -->
              <v-row>
                <v-col cols="8">
                  <date-picker
                    :value.sync="filterDate"
                    :label="$t('fields.date')"
                    outlined />
                </v-col>
                <v-col cols="4">
                  <v-btn
                    color="primary"
                    outlined
                    text
                    @click="printDaily">
                    {{ $t("common.print") }}
                  </v-btn>
                </v-col>
              </v-row>
              <!-- Pool -->
              <v-row no-gutters>
                <v-col cols="9">
                  <v-card
                    outlined
                    tile>
                    <span class="text-h6">{{ $t("views.reports.sales.tableColumns.bettingPool") }}</span>
                  </v-card>
                </v-col>
                <v-col cols="3">
                  <v-card
                    outlined
                    tile>
                    <template v-if="report.bettingPool">
                      {{ report.bettingPool.displayNumber }}
                    </template>
                  </v-card>
                </v-col>
              </v-row>
              <!-- Code -->
              <v-row
                class="grey lighten-2"
                no-gutters>
                <v-col cols="9">
                  <v-card
                    outlined
                    tile>
                    <span class="text-h6">{{ $t("views.reports.sales.tableColumns.code") }}</span>
                  </v-card>
                </v-col>
                <v-col cols="3">
                  <v-card
                    outlined
                    tile>
                    <template v-if="report.bettingPool">
                      {{ report.bettingPool.code }}
                    </template>
                  </v-card>
                </v-col>
              </v-row>
              <!-- Initial Balance -->
              <v-row no-gutters>
                <v-col cols="9">
                  <v-card
                    outlined
                    tile>
                    <span class="text-h6">{{ $t("views.reports.sales.tableColumns.initialBalance") }}</span>
                  </v-card>
                </v-col>
                <v-col cols="3">
                  <v-card
                    outlined
                    tile>
                    {{ $formatter.asCurrency(report.initialBalance) }}
                  </v-card>
                </v-col>
              </v-row>
              <!-- Sold -->
              <v-row
                class="grey lighten-2"
                no-gutters>
                <v-col cols="9">
                  <v-card
                    outlined
                    tile>
                    <span class="text-h6">{{ $t("views.reports.sales.tableColumns.sale") }}</span>
                  </v-card>
                </v-col>
                <v-col cols="3">
                  <v-card
                    outlined
                    tile>
                    {{ $formatter.asCurrency(report.totalSold) }}
                  </v-card>
                </v-col>
              </v-row>
              <!-- Prizes -->
              <v-row no-gutters>
                <v-col cols="9">
                  <v-card
                    outlined
                    tile>
                    <span class="text-h6">{{ $t("views.reports.sales.tableColumns.prizes") }}</span>
                  </v-card>
                </v-col>
                <v-col cols="3">
                  <v-card
                    outlined
                    tile>
                    {{ $formatter.asCurrency(report.totalPrizes) }}
                  </v-card>
                </v-col>
              </v-row>
              <!-- Net sale -->
              <v-row
                class="grey lighten-2"
                no-gutters>
                <v-col cols="9">
                  <v-card
                    outlined
                    tile>
                    <span class="text-h6">{{ $t("views.reports.sales.tableColumns.net") }}</span>
                  </v-card>
                </v-col>
                <v-col cols="3">
                  <v-card
                    outlined
                    tile>
                    {{ $formatter.asCurrency(report.netSale) }}
                  </v-card>
                </v-col>
              </v-row>
              <!-- Commissions -->
              <v-row no-gutters>
                <v-col cols="9">
                  <v-card
                    outlined
                    tile>
                    <span class="text-h6">{{ $t("views.reports.sales.tableColumns.commissions") }}</span>
                  </v-card>
                </v-col>
                <v-col cols="3">
                  <v-card
                    outlined
                    tile>
                    {{ $formatter.asCurrency(report.commission) }}
                  </v-card>
                </v-col>
              </v-row>
              <!-- Jackpot -->
              <v-row
                class="grey lighten-2"
                no-gutters>
                <v-col cols="9">
                  <v-card
                    outlined
                    tile>
                    <span class="text-h6">{{ $t("views.reports.sales.tableColumns.jackpot") }}</span>
                  </v-card>
                </v-col>
                <v-col cols="3">
                  <v-card
                    outlined
                    tile>
                    {{ $formatter.asCurrency(report.jackpot) }}
                  </v-card>
                </v-col>
              </v-row>
              <!-- Purse -->
              <v-row no-gutters>
                <v-col cols="9">
                  <v-card
                    outlined
                    tile>
                    <span class="text-h6">{{ $t("views.reports.sales.tableColumns.purse") }}</span>
                  </v-card>
                </v-col>
                <v-col cols="3">
                  <v-card
                    outlined
                    tile>
                    {{ $formatter.asCurrency(report.purse) }}
                  </v-card>
                </v-col>
              </v-row>
              <!-- jackpot prizes -->
              <v-row
                v-if="report.totalJackpotPrizes > 0"
                no-gutters>
                <v-col cols="9">
                  <v-card
                    outlined
                    tile>
                    <span class="text-h6">{{ $t("views.reports.sales.tableColumns.jackpotPrizes") }}</span>
                  </v-card>
                </v-col>
                <v-col cols="3">
                  <v-card
                    outlined
                    tile>
                    {{ $formatter.asCurrency(report.totalJackpotPrizes) }}
                  </v-card>
                </v-col>
              </v-row>
              <!-- Up to date balance -->
              <v-row
                :class="{ 'grey lighten-2': report.totalJackpotPrizes <= 0 }"
                no-gutters>
                <v-col cols="9">
                  <v-card
                    outlined
                    tile>
                    <span class="text-h6">{{ $t("views.reports.sales.tableColumns.upToDateBalance") }}</span>
                  </v-card>
                </v-col>
                <v-col cols="3">
                  <v-card
                    outlined
                    tile>
                    {{ $formatter.asCurrency(report.upToDateBalance) }}
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </v-tab-item>
          <!-- Weekly sales -->
          <v-tab-item>
            <v-container
              id="weekly-sale-summary-dialog"
              class="text-center">
              <v-row>
                <v-col cols="8">
                  <date-picker
                    :value.sync="filterDateWeekly"
                    :label="$t('fields.date')"
                    outlined />
                </v-col>
                <v-col cols="4">
                  <v-btn
                    color="primary"
                    outlined
                    text
                    @click="printWeekly">
                    {{ $t("common.print") }}
                  </v-btn>
                </v-col>
              </v-row>

              <v-row
                class="report-row"
                no-gutters>
                <v-col cols="9">
                  <v-card
                    outlined
                    tile>
                    <span class="text-h5">{{ $t("views.reports.sales.tableColumns.initialBalance") }}</span>
                  </v-card>
                </v-col>
                <v-col cols="3">
                  <v-card
                    outlined
                    tile>
                    {{ $formatter.asCurrency(weeklyReport.initialBalance) }}
                  </v-card>
                </v-col>
              </v-row>

              <v-row
                class="report-row"
                no-gutters>
                <v-col cols="9">
                  <v-card
                    outlined
                    tile>
                    <span class="text-h6">{{ $t("views.reports.sales.tableColumns.sale") }}</span>
                  </v-card>
                </v-col>
                <v-col cols="3">
                  <v-card
                    outlined
                    tile>
                    {{ $formatter.asCurrency(weeklyReport.totalSold) }}
                  </v-card>
                </v-col>
              </v-row>

              <v-row
                class="report-row"
                no-gutters>
                <v-col cols="9">
                  <v-card
                    outlined
                    tile>
                    <span class="text-h6">{{ $t("views.reports.sales.tableColumns.prizes") }}</span>
                  </v-card>
                </v-col>
                <v-col cols="3">
                  <v-card
                    outlined
                    tile>
                    {{ $formatter.asCurrency(weeklyReport.totalPrizes) }}
                  </v-card>
                </v-col>
              </v-row>

              <v-row
                class="report-row"
                no-gutters>
                <v-col cols="9">
                  <v-card
                    outlined
                    tile>
                    <span class="text-h6">{{ $t("views.reports.sales.tableColumns.net") }}</span>
                  </v-card>
                </v-col>
                <v-col cols="3">
                  <v-card
                    outlined
                    tile>
                    {{ $formatter.asCurrency(weeklyReport.netSale) }}
                  </v-card>
                </v-col>
              </v-row>

              <v-row
                class="report-row"
                no-gutters>
                <v-col cols="9">
                  <v-card
                    outlined
                    tile>
                    <span class="text-h6">{{ $t("views.reports.sales.tableColumns.commissions") }}</span>
                  </v-card>
                </v-col>
                <v-col cols="3">
                  <v-card
                    outlined
                    tile>
                    {{ $formatter.asCurrency(weeklyReport.commission) }}
                  </v-card>
                </v-col>
              </v-row>

              <v-row
                v-if="weeklyReport.netPurse !== null"
                class="report-row"
                no-gutters>
                <v-col cols="9">
                  <v-card
                    outlined
                    tile>
                    <span class="text-h6">{{ $t("views.reports.sales.tableColumns.purse") }}</span>
                  </v-card>
                </v-col>
                <v-col cols="3">
                  <v-card
                    outlined
                    tile>
                    {{ $formatter.asCurrency(weeklyReport.netPurse) }}
                  </v-card>
                </v-col>
              </v-row>

              <v-row
                class="report-row"
                no-gutters>
                <v-col cols="9">
                  <v-card
                    outlined
                    tile>
                    <span class="text-h6">{{ $t("views.reports.sales.tableColumns.accumulatedPurse") }}</span>
                  </v-card>
                </v-col>
                <v-col cols="3">
                  <v-card
                    outlined
                    tile>
                    {{ $formatter.asCurrency(weeklyReport.purse) }}
                  </v-card>
                </v-col>
              </v-row>

              <v-row
                class="report-row"
                no-gutters>
                <v-col cols="9">
                  <v-card
                    outlined
                    tile>
                    <span class="text-h6">{{ $t("views.reports.sales.tableColumns.accumulatedJackpot") }}</span>
                  </v-card>
                </v-col>
                <v-col cols="3">
                  <v-card
                    outlined
                    tile>
                    {{ $formatter.asCurrency(weeklyReport.jackpot) }}
                  </v-card>
                </v-col>
              </v-row>

              <v-row
                v-if="weeklyReport.totalJackpotPrizes > 0"
                class="report-row"
                no-gutters>
                <v-col cols="9">
                  <v-card
                    outlined
                    tile>
                    <span class="text-h6">{{ $t("views.reports.sales.tableColumns.jackpotPrizes") }}</span>
                  </v-card>
                </v-col>
                <v-col cols="3">
                  <v-card
                    outlined
                    tile>
                    {{ $formatter.asCurrency(weeklyReport.totalJackpotPrizes) }}
                  </v-card>
                </v-col>
              </v-row>

              <v-row
                v-if="weeklyReport.isCurrentWeek !== true"
                class="report-row"
                no-gutters>
                <v-col cols="9">
                  <v-card
                    outlined
                    tile>
                    <span class="text-h6">{{ $t("views.reports.sales.tableColumns.cutOffBalance") }}</span>
                  </v-card>
                </v-col>
                <v-col cols="3">
                  <v-card
                    outlined
                    tile>
                    {{ $formatter.asCurrency(weeklyReport.cutoffBalance) }}
                  </v-card>
                </v-col>
              </v-row>
              <v-row
                v-if="weeklyReport.isCurrentWeek === true"
                class="report-row"
                no-gutters>
                <v-col cols="9">
                  <v-card
                    outlined
                    tile>
                    <span class="text-h6">{{ $t("views.sales.daily.tableColumns.lastWeekCutOffBalance") }}</span>
                  </v-card>
                </v-col>
                <v-col cols="3">
                  <v-card
                    outlined
                    tile>
                    {{ $formatter.asCurrency(weeklyReport.lastWeekCutoffBalance) }}
                  </v-card>
                </v-col>
              </v-row>
              <v-row
                v-if="weeklyReport.is_current_week === true"
                class="report-row"
                no-gutters>
                <v-col cols="9">
                  <v-card
                    outlined
                    tile>
                    <span class="text-h6">{{ $t("views.sales.daily.tableColumns.lastWeekCutOffBalance") }}</span>
                  </v-card>
                </v-col>
                <v-col cols="3">
                  <v-card
                    outlined
                    tile>
                    {{ $formatter.asCurrency(weeklyReport.last_week_cutoff_balance) }}
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="primary"
          text
          @click="closeDialog">
          {{ $t("common.close") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import DatePicker from "../../components/inputs/DatePicker";
import moment from "moment";
import PrintUtils from "@/helpers/PrintUtils";
import {
  getPoolDailySales,
  getPoolWeeklySaleSummary,
  getPrintPoolDailySales,
  getPrintPoolWeeklySales,
} from "@/api/sale.js";

export default {
  name: "PoolSalesDialog",
  components: {
    DatePicker,
  },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      filterDate: moment().
        format("YYYY-MM-DD"),
      filterDateWeekly: moment().
        format("YYYY-MM-DD"),
      report: {},
      weeklyReport: {
        netPurse: null,
      },
      tab: 0,
    };
  },
  watch: {
    show(value) {
      if (value) {
        this.fetchSalesByDate();
        this.fetchWeeklySalesByDate();
        PrintUtils.setup();
      }
    },
    filterDate() {
      this.fetchSalesByDate();
    },
    filterDateWeekly() {
      this.fetchWeeklySalesByDate();
    },
  },
  methods: {
    closeDialog() {
      this.$emit("dismiss", false);
    },
    fetchSalesByDate() {
      const filters = {
        date: this.filterDate,
        bettingPoolId: this.$store.getters.bettingPool,
      };
      getPoolDailySales(filters).
        then(response => {
          this.report = response.data.sales[0];
        });
    },
    printDaily() {
      const filters = {
        date: this.filterDate,
        bettingPoolId: this.$store.getters.bettingPool,
      };
      getPrintPoolDailySales(filters).
        then(response => {
          const { documentLines } = response.data;

          PrintUtils.printDocumentLines(documentLines);
        });
    },
    printWeekly() {
      const filters = {
        date: this.filterDate,
        bettingPoolId: this.$store.getters.bettingPool,
      };
      getPrintPoolWeeklySales(filters).
        then(response => {
          const { documentLines } = response.data;

          PrintUtils.printDocumentLines(documentLines);
        });
    },
    fetchWeeklySalesByDate() {
      const filters = {
        date: this.filterDateWeekly,
        bettingPoolId: this.$store.getters.bettingPool,
      };
      getPoolWeeklySaleSummary(filters).
        then(response => {
          this.weeklyReport = response.data.report;
        });
    },
  },
};
</script>

<style
  lang="scss"
  scoped>
#sale-summary-dialog .v-card.v-sheet {
  background-color: transparent;
  height: 100%;
}

#weekly-sale-summary-dialog .v-card.v-sheet {
  background-color: transparent;
  height: 100%;
}

.report-row:nth-child(even) {
  background-color: #e0e0e0;
}
</style>
