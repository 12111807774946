<template>
  <v-row
    class="full-height"
    no-gutters>
    <portal to="navbar">
      <v-tabs
        v-model="selectedTab"
        background-color="transparent">
        <v-tab
          key="monitor"
          :disabled="gameInProgress">
          <strong>{{ $t("views.tickets.monitor.name") }}</strong>
        </v-tab>
        <v-tab
          key="result"
          :disabled="gameInProgress">
          <strong>{{ $t("views.roulette.results.title") }}</strong>
        </v-tab>
        <v-tab key="roulette">
          <strong>{{ $t("views.tickets.create.game_names.roulette") }}</strong>
        </v-tab>
      </v-tabs>
    </portal>
    <v-col
      cols="12">
      <v-tabs-items
        :value="selectedTab"
        class="full-height transparent-background">
        <v-tab-item
          key="monitor"
          class="full-height transparent-background">
          <ticket-monitor ref="ticketMonitor" />
        </v-tab-item>
        <v-tab-item
          key="result"
          class="full-height transparent-background">
          <result />
        </v-tab-item>
        <v-tab-item
          key="roulette"
          class="full-height transparent-background">
          <roulette
            @round-in-progress="gameInProgress = true"
            @round-complete="gameInProgress = false" />
        </v-tab-item>
      </v-tabs-items>
    </v-col>
  </v-row>
</template>
<script>
import Roulette from "@/pages/ticket/games/Roulette.vue";
import TicketMonitor from "@/pages/ticket/TicketMonitor.vue";
import Result from "@/pages/result/Result.vue";

const TABS = Object.freeze({
  monitorTab: 0,
  resultsTab: 1,
  ticketsCreateTab: 2,
});

export default {
  name: "TicketCreate",
  components: {
    TicketMonitor,
    Roulette,
    Result,
  },
  data() {
    return {
      selectedTab: TABS.ticketsCreateTab,
      gameInProgress: false,
      TABS,
    };
  },
  watch: {
    gameInProgress(value) {
      if (value && this.selectedTab !== TABS.ticketsCreateTab) {
        this.selectedTab = TABS.ticketsCreateTab;
      }
    },
    selectedTab(value) {
      if (TABS.monitorTab === value) {
        this.$refs.ticketMonitor.fetchTickets();
      }
    },
  },
};
</script>

<style lang="scss">
div.tab-with-error {
  background: #ffb8b8;
  border-top: 2px solid red;
}

.theme--light.v-tabs-items.transparent-background {
  background: transparent;
}

.full-height {
  height: 100%;
}

</style>
