<template>
  <v-row>
    <v-col cols="12">
      <div id="board">
        <table>
          <tbody>
            <tr id="r1">
              <roulette-cell
                v-for="trCell in row1"
                :key="trCell.text"
                :value="trCell.value"
                :color="trCell.color"
                :text="trCell.text"
                :rowspan="trCell.rowspan"
                :colspan="trCell.colspan"
                :type="trCell.type"
                @selected-play="handleSelectedPlay" />
            </tr>
            <tr>
              <roulette-cell
                v-for="trCell in row2"
                :key="trCell.text"
                :value="trCell.value"
                :color="trCell.color"
                :text="trCell.text"
                :rowspan="trCell.rowspan"
                :colspan="trCell.colspan"
                :type="trCell.type"
                @selected-play="handleSelectedPlay" />
            </tr>
            <tr>
              <roulette-cell
                v-for="trCell in row3"
                :key="trCell.text"
                :value="trCell.value"
                :color="trCell.color"
                :text="trCell.text"
                :rowspan="trCell.rowspan"
                :colspan="trCell.colspan"
                :type="trCell.type"
                @selected-play="handleSelectedPlay" />
            </tr>
            <tr>
              <td />
              <roulette-cell
                v-for="trCell in row4"
                :key="trCell.text"
                :value="trCell.value"
                :color="trCell.color"
                :text="trCell.text"
                :rowspan="trCell.rowspan"
                :colspan="trCell.colspan"
                :no-rotate="trCell.noRotate"
                :span-d-in-line="trCell.spanDInLine"
                @selected-play="handleSelectedPlay" />
            </tr>
            <tr>
              <td />
              <roulette-cell
                v-for="trCell in row5"
                :key="trCell.text"
                :value="trCell.value"
                :color="trCell.color"
                :text="trCell.text"
                :rowspan="trCell.rowspan"
                :colspan="trCell.colspan"
                :no-rotate="trCell.noRotate"
                :span-d-in-line="trCell.spanDInLine"
                @selected-play="handleSelectedPlay" />
            </tr>
          </tbody>
        </table>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import Cell from "@/components/games/roulette/Cell.vue";
import PlayType from "@/imported/PlayType.json";

export default {
  name: "Board",
  components: {
    RouletteCell: Cell,
  },
  data() {
    return {
      row1: [
        {
          value: {
            play: "00",
          },
          text: "0",
          color: "green",
          rowspan: "3",
          type: "number",
        },
        {
          value: {
            play: "03",
          },
          text: "3",
          color: "red",
          type: "number",
        },
        {
          value: {
            play: "06",
          },
          text: "6",
          color: "black",
          type: "number",
        },
        {
          value: {
            play: "09",
          },
          text: "9",
          color: "red",
          type: "number",
        },
        {
          value: {
            play: "12",
          },
          text: "12",
          color: "red",
          type: "number",
        },
        {
          value: {
            play: "15",
          },
          text: "15",
          color: "black",
          type: "number",
        },
        {
          value: {
            play: "18",
          },
          text: "18",
          color: "red",
          type: "number",
        },
        {
          value: {
            play: "21",
          },
          text: "21",
          color: "red",
          type: "number",
        },
        {
          value: {
            play: "24",
          },
          text: "24",
          color: "black",
          type: "number",
        },
        {
          value: {
            play: "27",
          },
          text: "27",
          color: "red",
          type: "number",
        },
        {
          value: {
            play: "30",
          },
          text: "30",
          color: "red",
          type: "number",
        },
        {
          value: {
            play: "33",
          },
          text: "33",
          color: "black",
          type: "number",
        },
        {
          value: {
            play: "36",
          },
          text: "36",
          color: "red",
          type: "number",
        },
        {
          value: {
            play: ".3",
          },
          text: "2-1",
          color: "none",
        },
      ],
      row2: [
        {
          value: {
            play: "02",
          },
          text: "2",
          color: "black",
          type: "number",
        },
        {
          value: {
            play: "05",
          },
          text: "5",
          color: "red",
          type: "number",
        },
        {
          value: {
            play: "08",
          },
          text: "8",
          color: "black",
          type: "number",
        },
        {
          value: {
            play: "11",
          },
          text: "11",
          color: "black",
          type: "number",
        },
        {
          value: {
            play: "14",
          },
          text: "14",
          color: "red",
          type: "number",
        },
        {
          value: {
            play: "17",
          },
          text: "17",
          color: "black",
          type: "number",
        },
        {
          value: {
            play: "20",
          },
          text: "20",
          color: "black",
          type: "number",
        },
        {
          value: {
            play: "23",
          },
          text: "23",
          color: "red",
          type: "number",
        },
        {
          value: {
            play: "26",
          },
          text: "26",
          color: "black",
          type: "number",
        },
        {
          value: {
            play: "29",
          },
          text: "29",
          color: "black",
          type: "number",
        },
        {
          value: {
            play: "32",
          },
          text: "32",
          color: "red",
          type: "number",
        },
        {
          value: {
            play: "35",
          },
          text: "35",
          color: "black",
          type: "number",
        },
        {
          value: {
            value: 2,
            play: ".2",
          },
          text: "2-1",
          color: "none",
        },
      ],
      row3: [
        {
          value: {
            play: "01",
          },
          text: "1",
          color: "red",
          type: "number",
        },
        {
          value: {
            play: "04",
          },
          text: "4",
          color: "black",
          type: "number",
        },
        {
          value: {
            play: "07",
          },
          text: "7",
          color: "red",
          type: "number",
        },
        {
          value: {
            play: "10",
          },
          text: "10",
          color: "black",
          type: "number",
        },
        {
          value: {
            play: "13",
          },
          text: "13",
          color: "black",
          type: "number",
        },
        {
          value: {
            play: "16",
          },
          text: "16",
          color: "red",
          type: "number",
        },
        {
          value: {
            play: "19",
          },
          text: "19",
          color: "red",
          type: "number",
        },
        {
          value: {
            play: "22",
          },
          text: "22",
          color: "black",
          type: "number",
        },
        {
          value: {
            play: "25",
          },
          text: "25",
          color: "red",
          type: "number",
        },
        {
          value: {
            play: "28",
          },
          text: "28",
          color: "black",
          type: "number",
        },
        {
          value: {
            play: "31",
          },
          text: "31",
          color: "black",
          type: "number",
        },
        {
          value: {
            play: "34",
          },
          text: "34",
          color: "red",
          type: "number",
        },
        {
          value: {
            play: ".1",
          },
          text: "2-1",
          color: "none",
        },
      ],
      row4: [
        {
          value: {
            play: "12+1",
          },
          text: "1 to 12",
          color: "none",
          colspan: "4",
          noRotate: true,
          spanDInLine: true,
        },
        {
          value: {
            play: "12+2",
          },
          text: "13 to 24",
          color: "none",
          colspan: "4",
          noRotate: true,
          spanDInLine: true,
        },
        {
          value: {
            play: "12+3",
          },
          text: "25 to 36",
          color: "none",
          colspan: "4",
          noRotate: true,
          spanDInLine: true,
        },
      ],
      row5: [
        {
          value: {
            play: "1-",
          },
          text: "1 to 18",
          color: "none",
          colspan: "2",
          noRotate: true,
          spanDInLine: true,
        },
        {
          value: {
            play: "++",
          },
          text: "Even",
          color: "none",
          colspan: "2",
          noRotate: true,
          spanDInLine: true,
        },
        {
          value: {
            play: "+",
          },
          text: "red",
          color: "none",
          colspan: "2",
          noRotate: true,
          spanDInLine: true,
        },
        {
          value: {
            play: "-",
          },
          text: "black",
          color: "none",
          colspan: "2",
          noRotate: true,
          spanDInLine: true,
        },
        {
          value: {
            play: "--",
          },
          text: "Odd",
          color: "none",
          colspan: "2",
          noRotate: true,
          spanDInLine: true,
        },
        {
          value: {
            play: "2-",
          },
          text: "19 to 36",
          color: "none",
          colspan: "2",
          noRotate: true,
          spanDInLine: true,
        },
      ],
    };
  },
  methods: {
    handleSelectedPlay($event) {
      this.$emit("click-key", $event);
    },
  },
};
</script>

<style
  lang="scss"
  scoped>
#board {
  background: #1d471d;
  border-style: double;
  border-color: #9db091;
  border-width: 5px;
  border-radius: 15px;

  table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    table-layout: fixed;

    td:empty {
      visibility: hidden;
    }

    @media (min-width: 1024px) {
      tr th, tr td {
        // 1024
        width: 7%;
        height: 5%;
        padding: 4px;
      }
    }

    @media (max-width: 1440px) {
      tr th, tr td {
        // 1024
        width: 5%;
        padding: 4px;
      }
    }

  }
}
</style>
