export function dozenPlayPreviewText(value) {
  switch (value) {
    case 1:
      return "1st 12";
    case 2:
      return "2nd 12";
    case 3:
      return "3rd 12";
  }
}

export default {
  methods: {
    dozenPlayPreviewText
  },
};
