<template>
  <v-dialog
    ref="dialog"
    :value="show"
    width="600"
    @click:outside="closeDialog">
    <v-card>
      <v-card-title class="headline grey lighten-2">
        {{ $t("views.tickets.create.duplicate_ticket") }}
      </v-card-title>
      <v-container>
        <v-row>
          <v-col cols="12">
            <validation-observer
              ref="validationObserver">
              <validation-provider
                v-slot="{ errors }"
                :rules="validations.sequenceNumber"
                name="sequenceNumber"
                slim>
                <v-text-field
                  ref="sequenceInput"
                  v-model="sequenceNumber"
                  :label="$t('views.tickets.create.duplicate')"
                  autofocus
                  data-vv-validate-on="change"
                  :error-messages="errors"
                  name="sequenceNumber"
                  type="text"
                  maxlength="4"
                  @keyup.enter="duplicateTicket" />
              </validation-provider>
            </validation-observer>
          </v-col>
        </v-row>
      </v-container>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="secondary"
          text
          @click="closeDialog">
          {{ $t("common.close") }}
        </v-btn>
        <v-btn
          color="primary"
          text
          @click="duplicateTicket">
          {{ $t("views.tickets.create.duplicate") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ContentLoader from "../../mixins/ContentLoader.js";
import Validatable from "@/mixins/Validatable.js";
import { duplicateTicket } from "@/api/ticket.js";
import { ValidationObserver } from "vee-validate";

export default {
  name: "DuplicateTicketDialog",
  components: {
    ValidationObserver,
  },
  mixins: [ContentLoader, Validatable],
  props: {
    show: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      sequenceNumber: null,
      validations: {
        sequenceNumber: "required",
      },
    };
  },
  methods: {
    duplicateTicket() {
      const { validationObserver } = this.$refs;
      validationObserver.validate().then(isValid => {
        if (isValid) {
          duplicateTicket(this.sequenceNumber).then(response => {
            const { ticket } = response.data;
            this.$emit("duplicate-ticket", ticket.plays);
            this.closeDialog();
          })
            .catch(e => {
                this.handleErrorResponse(this.$refs["validationObserver"], e);
              },
            );
        }
      });
    },
    closeDialog() {
      this.sequenceNumber = null;
      this.$refs.validationObserver.reset();
      this.$emit("dismiss");
    },
  },
};
</script>

<style
  lang="scss"
  scoped>
#sale-summary-dialog .v-card.v-sheet {
  background-color: transparent;
  height: 100%;
}
</style>
