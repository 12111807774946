<template>
  <v-dialog
    :value="show"
    width="400"
    @click:outside="$emit('dismiss')">
    <v-card>
      <v-card-text class="text-center p-4">
        <ticket-preview
          v-if="ticket"
          :ticket="ticket" />
        <v-progress-circular
          v-else
          class="text-center"
          indeterminate />
      </v-card-text>
      <v-card-actions>
        <v-btn @click="$emit('dismiss')">
          Cancel
        </v-btn>
        <v-spacer />
        <v-btn
          color="danger"
          dark
          @click="confirmDelete = true">
          <v-icon
            dark
            left>
            mdi-cancel
          </v-icon>
          {{ $t("crudActions.cancel") }}
        </v-btn>
      </v-card-actions>
      <confirm-dialog
        v-if="ticket"
        :show.sync="confirmDelete"
        :title="$t('views.tickets.monitor.cancel_ticket')"
        :message="$t('views.tickets.monitor.cancel_ticket_message')"
        cancel-message="No"
        @confirm="cancelTicket">
        <template #confirm-button="{ onConfirm }">
          <v-btn
            color="danger"
            text
            @click="onConfirm">
            {{ $t("common.yes") }}
          </v-btn>
        </template>
      </confirm-dialog>
    </v-card>
  </v-dialog>
</template>

<script>
import { cancelTicket, fetchTicketById } from "@/api/ticket.js";
import ConfirmDialog from "@/components/dialog/ConfirmDialog.vue";
import TicketPreview from "@/components/tickets/TicketPreview.vue";

export default {
  name: "CancelTicketDialog",
  components: {
    TicketPreview,
    ConfirmDialog,
  },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    ticketId: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      confirmDelete: false,
      ticket: null,
    };
  },
  watch: {
    "ticketId.value"(value) {
      if (value) {
        this.fetchTicket(value);
      }
    },
  },
  methods: {
    fetchTicket(ticketId) {
      fetchTicketById(ticketId).
        then(response => {
          this.ticket = response.data.ticket;
        });
    },
    cancelTicket() {
      cancelTicket(this.ticket.id).
        then(response => {
          this.$emit("cancel", response.data.ticket);
          this.confirmDelete = false;
        }).
        catch(error => {
          console.error(error);
          this.confirmDelete = false;
        });
    },
  },
};
</script>
