<template>
  <v-dialog
    :value="show"
    v-bind="$attrs"
    width="500"
    @click:outside="$emit('dismiss')">
    <v-card>
      <v-card-title class="headline grey lighten-2">
        {{ $t("views.tickets.create.pay_ticket") }}
      </v-card-title>
      <v-divider />
      <v-container>
        <template v-if="ticket === null">
          <form @submit.prevent="findByCode">
            <input
              type="submit"
              hidden>
            <v-row>
              <v-col cols="12">
                <validation-observer
                  ref="paymentValidationObserver">
                  <validation-provider
                    v-slot="{ errors }"
                    :rules="validations.code"
                    slim
                    name="payment_code">
                    <v-text-field
                      ref="code"
                      v-model="code"
                      :label="$t('views.tickets.create.payment_code')"
                      :error-messages="errors"
                      autofocus />
                  </validation-provider>
                </validation-observer>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                offset="4"
                cols="4">
                <v-btn
                  block
                  color="primary"
                  @click="findByCode">
                  {{ $t("views.tickets.create.find_ticket") }}
                </v-btn>
              </v-col>
            </v-row>
          </form>
        </template>
        <v-row
          v-if="ticket !== null">
          <v-col cols="12">
            <v-alert
              v-if="ticket.paid"
              dense
              outlined
              text
              type="warning"
              icon="mdi-information-variant"
              class="mt-5">
              {{ $t("views.tickets.create.ticket_already_paid") }}
            </v-alert>
            <v-alert
              v-if="ticket.status !== TicketStatus.winner"
              dense
              outlined
              text
              type="error"
              icon="mdi-information-variant"
              class="mt-5">
              {{ $t("views.tickets.create.not_a_winning_ticket") }}
            </v-alert>
            <ticket-preview
              v-if="ticket"
              :ticket="ticket" />
          </v-col>
        </v-row>
      </v-container>
      <v-card-actions>
        <v-btn
          v-if="ticket === null"
          color="secondary"
          @click="$emit('dismiss')">
          Cancel
        </v-btn>
        <v-btn
          v-if="ticket !== null"
          color="secondary"
          @click="clearFields">
          {{ $t("views.tickets.create.find_another") }}
        </v-btn>
        <v-spacer />
        <v-btn
          color="primary"
          :disabled="ticket == null || ticket.paid || ticket.status !== TicketStatus.winner"
          @click="payTicket">
          {{ $t("views.tickets.create.pay") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import { fetchTicketByCode, payTicket } from "@/api/ticket.js";
import TicketPreview from "@/components/tickets/TicketPreview.vue";
import TicketStatus from "@/imported/TicketStatus.json";

export default {
  name: "PayTicketDialog",
  components: {
    ValidationObserver,
    TicketPreview,
  },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      TicketStatus,
      ticket: null,
      code: null,
      validations: {
        code: "required",
      },
    };
  },
  watch: {
    "show"(value) {
      if (value) {
        this.$nextTick(() => {
          this.$refs.paymentValidationObserver.reset();
        });
      } else {
        this.clearFields();
      }
    },
  },
  methods: {
    findByCode() {
      const paymentObserver = this.$refs.paymentValidationObserver;

      paymentObserver.validate().
        then(formWasValid => {
          if (formWasValid) {
            fetchTicketByCode(this.code).
              then(response => {
                this.ticket = response.data.ticket;
              });
          }
        });
    },
    clearFields() {
      this.code = null;
      this.ticket = null;
    },
    payTicket() {
      payTicket(this.ticket.id).
        then(() => {
          this.$swal.fire({
            position: "center",
            icon: "success",
            title: this.$t("views.tickets.create.ticket_paid"),
            showConfirmButton: false,
            timer: 1500,
          });
        }).
        then(() => {
          this.$emit("paid", { ticket: this.ticket})
          this.$emit("dismiss");
        });
    },
  },
};
</script>

<style
  lang="scss"
  scoped>
.action-button {
  background-color: #89bb90;
  border: 1px solid white;
  border-radius: 5px;
  cursor: pointer;

  span {
    font-size: 1.5em;
    color: white;
  }
}
</style>
