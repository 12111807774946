import httpClient from "@/api/httpClient.js";

const resource = "games";

export function fetchHelp(gameId) {
  return httpClient.get(`/${resource}/${gameId}`);
}

export function fetchParams(gameId) {
  return httpClient.get(`/${resource}/${gameId}/params`);
}
