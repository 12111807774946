const TICK_CLOCK = "Tick 🕒",
  SET_TIME = "Set time",
  SET_TICK = "Set tick",
  SET_TIME_ZONE = "Set timezone";

export {
  TICK_CLOCK,
  SET_TIME,
  SET_TICK,
  SET_TIME_ZONE,
};
