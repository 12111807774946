<template>
  <v-progress-linear
    :value="progressPercentage"
    color="green darken-2"
    background-color="green darken-1"
    class="soft-border"
    height="30"
    dark>
    <template #default="{value}">
      <div class="w-100 d-flex justify-space-around">
        <div class="info-wrapper">
          <strong class="round-info">
            {{ $t("views.tickets.create.round") }}: {{ round.value.sequence }}
          </strong>
          <strong class="round-time">
            <font-awesome-icon icon="clock" />
            {{ round.value.timeLeft || "--:--" }}
          </strong>
        </div>
        <strong>{{ Math.ceil(value) }}%</strong>
        <ClockDisplay />
      </div>
    </template>
  </v-progress-linear>
</template>

<script>
import ClockDisplay from "@/components/ClockDisplay.vue";
import moment from "moment-timezone";

import { createNamespacedHelpers } from "vuex";
import { MODULE_NAME } from "@/store/modules/clock";

const { mapState } = createNamespacedHelpers(MODULE_NAME);

export default {
  name: "RoundInformationHeader",
  components: { ClockDisplay },
  props: {
    round: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState({
      currentTime: state => state.time,
      timeZone: state => state.timeZone,
    }),
    progressPercentage() {
      const startTime = this.round.value.startTimeInMillis,
        closingTime = this.round.value.closeTimeInMillis,
        currentTime = this.currentTime;

      if (closingTime === null || currentTime >= closingTime) {
        return 100;
      }

      const roundDuration = closingTime - startTime;

      return 100 - (closingTime - currentTime) / roundDuration * 100;
    },
  },
};
</script>

<style scoped>
.w-100 {
  width: 100%;
}
</style>
